// Libraries
import React from 'react';

const fontfamilies = () => {

  return {
    'Regular': require('../fonts/' + global.settings.font_regular),
    'Semibold': require('../fonts/' + global.settings.font_bold),
  }
};
  
export default fontfamilies;

