// Libraries
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

// Styles

// Variables
const defaultBackgroundColor = 'rgba(0,0,0,0)'; // transparant
const defaultBorderColor = 'rgba(0,0,0,0)'; // transparant

// functions
export const Table = ( props ) => {
  const backgroundColor = props.backgroundColor || defaultBackgroundColor;
  const borderColor = props.borderColor || defaultBorderColor;
  return (
    <View 
      style={{ 
        backgroundColor: backgroundColor, 
        padding: 5, 
        borderColor: borderColor, 
        borderRadius: 8, 
        borderWidth: 1, 
      }}
    >
      { props.children }
    </View>
  );
}

export const Row = ( props ) => {
  return (
    <View style={{ flexDirection: 'row' }} >
      { props.children }
    </View>
  );
}

export const Cell = ( props ) => {
  return (
    <View style={{ flex: 1 }} >
      { props.children }
    </View>
  );
}