// Libraries
import React, { useReducer } from 'react';

// Global Context en Dispatcher
export const GlobalContext = React.createContext({});
export const GlobalDispatcher = React.createContext({});

// importeer alle Actions
import { commonActions, commonInitialState } from './actions/commonActions';

const GlobalState = props => {

  // Maak Reducers voor alle actions
  const [ commonState, commonDispatch ] = useReducer( commonActions, commonInitialState );


  // In de context plaats je alle states 
  // en in de dispatcher alles dispatches
  return(
    <GlobalContext.Provider value={{ commonState }}>
      <GlobalDispatcher.Provider value={{ commonDispatch }}>
        { props.children }
      </GlobalDispatcher.Provider>
    </GlobalContext.Provider>
  )
}

export default GlobalState;
