/// Libraries
import React, { useState, useEffect, useContext, useMemo, useCallback, useRef} from 'react';
import { View, Text, FlatList, TouchableOpacity, Image, RefreshControl, Dimensions, TextInput, StyleSheet, AccessibilityInfo, findNodeHandle, Platform, UIManager} from "react-native";

// Styling 
import FncColors from '../styles/colors';
let Colors = [];
import { mobieleStyling as s } from '../styles/mobiel';
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// Functions
import { adjustUri, textReplace, adjustWebTekst, readProperty, customConsoleLog, openUrlNewTab, sendGa } from '../functions/common';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_MODULE, SET_PARAM_CACHE } from '../context/actions/commonActions';

// Components
import OuterContainer from '../components/outerContainer'
import MainContainer from '../components/mainContainer'
import TopContainer from '../components/topContainer'
import PostContainer from '../components/postContainer'
import BottomContainer from '../components/bottomContainer'
import HeaderFrame from '../components/headerFrame';
import Header from '../components/header';
import FooterFrame from '../components/footerFrame';
import Footer from '../components/footer';
import Harmonica from '../components/harmonica';
import { P, H1, H2, H3, H4, Space, PBold, SplitAlineas} from '../components/text';
import { TextBox, TextBoxMobile, ImageTextBoxMobile, ImageOnlyBox, ImageTextBox} from '../components/textBox';
import { Button, ButtonHome, ButtonHomeMobile} from '../components/button';
import IconSetter from './iconSetter';
import CardModule from '../components/cardModule';


//Variabele

var googleDone = false;

export default function ModuleOverzichtContainer( props ) {
  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo.png');
  
  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const param_cache = commonContext.param_cache;

  //Props
  const mobile = props.mobile || false;
  const tablet = props.tablet || false;
  const navigation = props.navigation;
  const modules = props.modules || [];
  const { styles } = useStyles();

  if(!googleDone){
    googleDone = true;
    sendGa('moduleOverzicht', '');
  }

  const open_module = ( module_naam ) => {
    navigation.push( "Main", {module: module_naam });
  }

  return (
    <View style={ styles('grid') } >
      {
        modules.map((module, index) => {
          return (
            <CardModule
              key={index}
              label={module.module_label}
              image={module.module_afbeelding}
              onPress={ () =>[open_module( module.module_naam ), param_cache.moduleNaam === module.module_naam ? null : commonDispatch({ type: SET_PARAM_CACHE, value: {
                "goto": 0,
                "chain":[]
              }})]}
            />
          )
        })
      }

    </View>
  );
};

// Style
const styler = StyleSheet.create({
  container: { 
  },
});

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      bgWhite: {
        maxWidth: 1430,
        width: '100%',
        backgroundColor: 'white',
        alignSelf: 'center'
      },
      container: {
        maxWidth: 1310,
        width: '100%',
        padding: 20,
        backgroundColor: 'white'
      },
      categoriesList: {
        maxWidth: 1310,
        paddingHorizontal: 20,
        width: '100%',
        paddingTop: 20,
        backgroundColor: 'white'
      },
      laadtekst: {
        fontFamily: 'Book',
        fontSize: 14,
        alignSelf: 'center',
        marginTop: 5,
      },
      centeredButton: {
        width: '100%'
      },
      buttonPosition: {
        alignItems: 'center',
        width: '100%'
      },
      content: {
        height: 'auto',
        width: '100%',
        alignItems: 'center'
      },
      grid: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10
      },
      col: {
        width: '100%',
      },
      categories: {
        width: '100%'
      },
      filterTitle: {
        fontFamily: 'Regular',
        display: 'none',
        fontSize: 16,
        paddingTop: 20
      },
      noResultsText: {
        fontFamily: 'Regular',
        fontSize: 16,
        marginBottom: 20
      },
      whatToDo: {
        fontFamily: 'Regular',
        fontSize: 16,
        lineHeight: 24
      }
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      col: {
        width: '48.5%'
      },
      promoCol: {
        width: '49%'
      },
      centeredButton: {
        width: '40%'
      },
      categories: {
        display: 'flex',
        alignItems: 'center'
      },
      categoriesContainer: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: 1310
      },
      filterTitle: {
        display: 'block',
      }
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
      col: {
        width: '23%'
      }
    }
  }
)