// Libraries
import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Variables

// functions
export const P = ( props ) => {
  Colors = FncColors();
  const color = props.color || Colors[9];
  const fontSize = props.fontSize || 15;
  const maxWidth = props.maxWidth || '100%';
  const textAlign = props.textAlign || 'left';
  const alignSelf = props.alignSelf || '';
  const fontStyle = props.fontStyle || 'normal';
  const fontWeight = props.fontWeight || 'normal';
  const fontFamily = props.fontFamily || 'Regular'; // Bold, Italic
  const marginHorizontal = props.marginHorizontal || '0px';
  const lineHeight = props.lineHeight;
  const accessible = props.accessible;
  const isHovered = props.isHovered;
  const marginTop = props.marginTop || 0;
  const marginBottom = props.marginBottom || 0;
  
  return (
    <Text accessible={accessible} accessibilityRole='text' style={[ styles.p, {  fontFamily: fontFamily, lineHeight: lineHeight, fontWeight: fontWeight, fontStyle: fontStyle, color: color, fontSize: fontSize, maxWidth: maxWidth, marginTop: marginTop, marginBottom: marginBottom, textAlign: textAlign, alignSelf: alignSelf, marginHorizontal: marginHorizontal }
    , isHovered ? {borderBottomWidth: 1, borderBottomColor: 'black'} : {} ]}>
        { props.children }
    </Text>
  );
}

export const SplitAlineas = ( props ) => {
  Colors = FncColors();
  const color = props.color || Colors[9];
  const textAlign = props.textAlign || 'left';
  const fontSize = props.fontSize || 15;
  const lineHeight = props.lineHeight || fontSize;
  const marginBottom = props.marginBottom || 0;
  const paddingHorizontal = props.paddingHorizontal || 20;
  const textArray = props.text ? props.text.split('\n') : 'Geef je tekst mee als text\nvoor tekst over meerdere regels'.split('\n');

  return (
    <>
    {
      textArray.map( ( text, index ) => {
        return (
          <Text accessible={true} accessibilityLabel={text} accessibilityRole='text' key={ index } style={[ styles.splitArea, { fontSize: fontSize, lineHeight: lineHeight, color: color, textAlign: textAlign, paddingHorizontal: paddingHorizontal, marginBottom: marginBottom } ]}>
             { text.replace('\r', '') }
          </Text>        
        )
      })
    }
    </>
  );  
}


// functions
export const Label = ( props ) => {
  Colors = FncColors();
  const color = props.color || Colors[9];
  const textAlign = props.textAlign || 'left';
  return (
    <Text style={[ styles.label, { color: color, textAlign: textAlign } ]}>
        { props.children }
    </Text>
  );
}
export const H1 = ( props ) => {
  Colors = FncColors();
  const color = props.color || Colors[9];
  const textAlign = props.textAlign || 'left';
  const fontSize = props.fontSize || 40;
  const maxWidth = props.maxWidth || '80%'
  const alignSelf = props.alignSelf || 'center';
  const paddingHorizontal = props.paddingHorizontal || 0;
  const paddingVertical = props.paddingVertical || 0;
  const accessible = props.accessible;
  const realHeader = props.realHeader || false;
  const level = props.level || "";

  return (
    <Text 
      accessible={accessible} 
      accessibilityRole={ realHeader ? 'header' : ''} 
      aria-level={ realHeader ? level : "" }      
      style={[ styles.h1, { fontSize: fontSize, paddingHorizontal: paddingHorizontal, paddingVertical: paddingVertical, color: color, textAlign: textAlign, maxWidth: maxWidth, alignSelf: alignSelf } ]}
    >
        { props.children }
    </Text>
  );
}

export const H2 = ( props ) => {
  Colors = FncColors();
  const color = props.color || Colors[9];
  const textAlign = props.textAlign || 'left';
  const alignSelf = props.alignSelf || 'center';
  const fontFamily = props.fontFamily || 'RegularItalic';
  const accessible = props.accessible;
  const realHeader = props.realHeader || false;
  const level = props.level || "";
  
  return (
    <Text 
      accessible={accessible} 
      accessibilityRole={ realHeader ? 'header' : ''} 
      aria-level={ realHeader ? level : "" }      
      style={[ styles.h2, { color: color, textAlign: textAlign, fontFamily: fontFamily, alignSelf: alignSelf } ]}
    >
        { props.children }
    </Text>
  );
}

export const H3 = ( props ) => {
  Colors = FncColors();
  const color = props.color || Colors[9];
  const textAlign = props.textAlign || 'left';
  const fontSize = props.fontSize || 19; 
  const maxWidth = props.maxWidth || '100%';
  const alignSelf = props.alignSelf || 'center';
  const accessible = props.accessible;
  const style = props.style;
  const realHeader = props.realHeader || false;
  const level = props.level || "";

  return (
    <Text 
      accessible={accessible} 
      accessibilityRole={ realHeader ? 'header' : ''} 
      aria-level={ realHeader ? level : "" }      
      style={[ styles.h3, { color: color, maxWidth: maxWidth, textAlign: textAlign, alignSelf: alignSelf, fontSize: fontSize }, style ]}>
        { props.children }
    </Text>
  );
}

export const H4 = ( props ) => {
  Colors = FncColors();
  const color = props.color || Colors[9];
  const textAlign = props.textAlign || 'left';
  const alignSelf = props.alignSelf || 'left';
  const fontSize = props.fontSize || 16; 
  const fontFamily = props.fontFamily || 'Semibold'
  const fontWeight = props.fontWeight || 'normal';
  const lineHeight = props.lineHeight;
  const maxWidth = props.maxWidth;
  const backgroundColor = props.backgroundColor;
  const accessible = props.accessible;
  const realHeader = props.realHeader || false;
  const level = props.level || "";
  
  return (
    <Text 
      accessible={accessible} 
      accessibilityRole={ realHeader ? 'header' : ''} 
      aria-level={ realHeader ? level : "" }      
      style={[ styles.h4, {fontFamily: fontFamily, fontWeight: fontWeight, color: color, textAlign: textAlign, alignSelf: alignSelf, maxWidth: maxWidth, fontSize: fontSize, lineHeight: lineHeight, backgroundColor: backgroundColor } ]}>
        { props.children }
    </Text>
  );
}

export const Space = ( props ) => {
  Colors = FncColors();
  const height = props.height || 5;
  return <View paddingVertical={ height } />
}

export const Center = ( props ) => {
  Colors = FncColors();
  return (
    <View style={{ alignItems: 'center' }}>
      { props.children }
    </View>
  ) 
}

// functions
export const Link = ( props ) => {
  Colors = FncColors();
  const onPress = props.onPress || function () { alert('maak onPress aan op buttton') };
  const color = props.color || Colors[8];
  const fontSize = props.fontSize || 24;
  const maxWidth = props.maxWidth || '100%';
  const textAlign = props.textAlign || 'left';
  const fontFamily = props.fontFamily || 'Bold'; // Regular, Bold, Italic
  const marginHorizontal = props.marginHorizontal || '20px';
  const marginBottom = props.marginBotton || 6;
  const lineHeight = props.lineHeight || 21;

  return (
    <TouchableOpacity onPress={ onPress }>
      <Text style={
        { 
          fontFamily: fontFamily, 
          color: color, 
          fontSize: fontSize, 
          maxWidth: maxWidth, 
          textAlign: textAlign, 
          marginHorizontal: marginHorizontal, 
          marginBottom: marginBottom,
          lineHeight: lineHeight
        } 
      }>
          { props.children }
      </Text>
    </TouchableOpacity>
  );
}


export const Line = ( props ) => {
  Colors = FncColors();
  const width = props.width || 1;
  const color = props.color || Colors[0];
  const marginVertical = props.marginVertical || 25;
  const marginHorizontal = props.marginHorizontal || 25;

  return (
      <View style={ { 
        flex:1, 
        borderBottomWidth: width, 
        borderColor: color , 
        marginVertical: marginVertical,
        marginHorizontal: marginHorizontal 
      } } />
    )
} 


// Style
const styles = StyleSheet.create({
  splitArea: {
    fontSize: 18,
    fontFamily: 'Regular',
    marginBottom: 12,
    lineHeight: 21,
  },
  p: {
    fontSize: 16,
    fontFamily: 'Regular',
    marginBottom: 0,
    lineHeight: 18,
  },
  label: {
    fontSize: 13,
    fontFamily: 'Semibold',
    marginTop: 6,
  },
  h1: {
    fontFamily: 'Semibold',
    marginBottom: 6,
  },
  h2: {
    fontSize: 30,
    fontFamily: 'Semibold',
    marginBottom: 6,
  },
  h3: {
    fontSize: 20,
    fontFamily: 'Semibold',
    marginBottom: 0,
  },
  h4: {
    marginBottom: 0,
  },
  h1White: {
    fontSize: 25,
    fontFamily: 'Semibold',
    alignSelf: 'center',
  }
});