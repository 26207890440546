// Libraries
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, Dimensions, Switch as RNSwitch, Text, View, Platform } from 'react-native';
import { Octicons, AntDesign } from '@expo/vector-icons';
import { Picker } from '@react-native-picker/picker';

// Functions
import { clickedOutsideView } from '../functions/common';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// components
import { P, Label, H1, H2, H3, H4, Space } from '../components/text';
import { Table, Row, Cell } from '../components/table';

//Default function
export default function DropDown( props ) {
  Colors = FncColors();
  const onChange = props.onChange || function (val) { console.log('maak onChange aan op DropDown: ' + val) };
  const value = props.value || '';
  const maxWidth = props.maxWidth;
  const mobile = props.mobile;
  const mode = props.mode || 'dropdown'; // dropdown 
  const items = props.items || [ { label: 'item 1', value: 'item 1'}, { label: 'item 2', value: 'item 2'}, { label: 'item 3', value: 'item 3'} ];
  const label = props.label || 'label';
  const style = props.style || styles.dropDown;
  const enabled = props.enabled || false;

  return (
    <View style={{ borderRadius: global.settings.border_radius, maxWidth: maxWidth, borderWidth: 1, borderColor: Colors[9], overflow: 'hidden', height: 40, marginHorizontal: 5, marginTop: 10, marginBottom: 10}}>
      <Row>      
        <Text></Text>
        <Cell>
          <Picker 
            style={ style }
            mode={ mode }
            selectedValue={ value }
            onValueChange={ onChange } 
            enabled={enabled}
            /*itemStyle={{ backgroundColor: Colors[1], color: Colors[4], fontFamily:"Regular", fontSize:30, width: '100%', borderWidth: 0}}*/
          >
          {
            items.map((item, index) => {
              return (
                <Picker.Item 
                  key={ item.value }
                  label={ item.label } 
                  value={ item.value } 
                  style={{ color: Colors[4], borderWidth: 0}}
                />
              )
            })
          }
          </Picker>
        </Cell>          
      </Row>
    </View>
  );
}

export const CustomDropDown = (props) => {
  Colors = FncColors();

  const flex = props.flex || 1;
  const onPress = props.onPress;
  const shift = props.shifted;
  const opties = props.opties || [{ label: "Dropdown 1", value: 1 }, { label: "Dropdown 2", value: 2 }, { label: "Dropdown 3", value: 3 }];
  let initValue = props.value;
  let enabled = props.enabled;
  let newAntwoord = 'Selecteer verblijf';
  const mobile = props.mobile || false;
  const tablet = props.tablet;
  const borderColor = props.borderColor;
  const dropdown_index = props.index;
  const onPressArrow = props.onPressArrow;
  const [arrowVisible, setArrowVisible] = useState(true);
  const [dropDown, setDropDown] = useState(false);
  const [antwoord, setAntwoord] = useState(shift.label);
  const aantalOpties = opties.length;

  const breedBeeldofTablet =  () => {
    if ( Dimensions.get('screen').width > 2500 || tablet){
      setArrowVisible(false);
    } else {
      setArrowVisible(true);
    }
  };

  useEffect(() => {
    breedBeeldofTablet();
    if(initValue == null || initValue == ''){
      initValue = 0;
    }

    //Zoek op de waarde van het object, niet de index bijvoorbeeld
    const dropdownIndex = opties.findIndex((obj => obj.value == initValue));
    setAntwoord(opties[dropdownIndex].label);
  },[initValue]);

  //Functie om de dropdown weer te sluiten
  const viewRef = useRef(null);
  clickedOutsideView(viewRef, setDropDown);

  return (
    <View style={mobile ? styles.dropDownMobile : [styles.dropDown, borderColor, { borderBottomWidth: enabled ? 1 : antwoord === 'Selecteer verblijf' ? 1 : 0}]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
        <TouchableOpacity accessibilityRole='menuitem' accessibilityLabel={'Dit is een dropdown menu. ' + antwoord} style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', minWidth: tablet ? 140 : 172, paddingTop: 3 }}  onPress={ mobile ? () => setDropDown(!dropDown) : enabled ? onPressArrow : null}>
          <P fontSize={tablet ? 12 : mobile ? 16 : 15} textAlign={'left'} color={ enabled ? Colors[9] : '#cfcfcf'}>{enabled ? antwoord : antwoord === 'Selecteer verblijf' ? newAntwoord : 'N.v.t'}</P>
         {enabled && arrowVisible ? <Octicons name="triangle-down" style={mobile ? {} : [styles.octicon, { paddingRight: 5}]} size={tablet ? 10 : mobile ? 16 : 28} color={Colors[9]} /> : null}
        </TouchableOpacity>
      </View>
      { dropDown && mobile ?
        <View ref={viewRef} style={mobile ? styles.dropDownViewMobile : [styles.dropDownView, {left: tablet ? -30 : (dropdown_index == 0 ? -68 : -64), top: -6, borderBottomRightRadius: 30, borderBottomLeftRadius: 30 }]}>
          {
            opties.map((data, index) => {
              if(index == 0 ){
                return false;
              }
              //console.log(data.value);
              //let label = data.label.replace("*", "");
              return (
                <TouchableOpacity accessibilityLabel={data.label} accessibilityRole='menuitem' key={data.value} style={[(aantalOpties === index + 1 && !mobile) ? {borderBottomLeftRadius: 30, borderBottomRightRadius: 30, paddingBottom: 12} : null, data.value === initValue ? {backgroundColor: Colors[9]} : {backgroundColor: Colors[0]}, { paddingVertical: 7, width: "100%", paddingLeft: tablet ? 30 : (mobile ? 4 : (dropdown_index == 0 ? 66 : 62)) }]} onPress={() => [setAntwoord(data.label), setDropDown(!dropDown), onPress(data.value)]}>
                    {/*data.value === initValue ? 
                      <View style={{ position: 'absolute', flex: flex, minWidth: tablet ? 135 : 272, alignSelf: 'flex-start', backgroundColor: Colors[9], height: tablet ? 30 : 35, zIndex: -1, left: 0, top: -5 }} /> 
                      : null*/
                    }
                    
                    <H4 fontFamily={'Regular'} fontSize={tablet ? 12 : mobile ? 16 : '1vw'} textAlign='left' color={data.value === initValue ? Colors[0] : Colors[9]}>{data.label}</H4>
                </TouchableOpacity>
              )
            })
          }
        </View>
      : null }
    </View>
  )
};

const styles = StyleSheet.create({
  dropDown:{
    borderRadius: 5,
    paddingHorizontal: 5,
    justifyContent: 'space-between',
    height: 30,
    zIndex: 2,
  },
  dropDownMobile:{

  },
  dropDownView:{
    width: '16.5vw',
    zIndex: 2,
  },
  dropDownViewMobile:{
    alignItems: 'flex-start',
    backgroundColor: Colors[0], 
    zIndex: 2,
  },
  octicon:{ 
    marginLeft: 5,
    position: 'relative',
    bottom: 5,
    right: 0
  },
});