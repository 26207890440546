// Libraries
import React from 'react';
import { StyleSheet, View, Image, Dimensions } from 'react-native';

// Styles

// Variables

// Default function
export default function OuterContainer( props ) {
  const style = props.style || styles;

  return (
    <View style={style.container}>
      { props.children }
    </View>
  );
}

// Style
const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    /*flexWrap: 'wrap',*/
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white'
  },
});