// Libraries
import React from 'react';
import { StyleSheet, Dimensions, View } from 'react-native';

// Styles

// Variables

// Default function
export default function PostContainer( props ) {
  const style = props.style || styles;
  const flex = props.flex || 10;

  return (
    <View style={ [style.post ] }>
      { props.children }
    </View>
  );
}

// Style
const styles = StyleSheet.create({
  post: {
    flexDirection: 'row',
    width: Dimensions.get('window').width,
  },
});