// Vaste kleuren

export default function FncColors() {

  // mag eventueel leeg zijn, dan worden onderstaande
  // kleuren gebruikt
  let env_colors = [];
  if(global.settings.colors){
    env_colors = global.settings.colors;
  }

  let colors = [
    '#FFFFFF',
    '#3FA1ED', 
    '#E4F0FB', /* Was #9FD0F6 */
    '#76D47B', /* Was #51D870 */
    '#054C38',
    '#A8EAB7',
    '#E55626',
    '#ffb036',
    '#f7f7f7',
    'black',
    '#e6e8fa',
    '#777777'
  ]

  for( var i=0; i< env_colors.length; i++ ) {
    colors[i] = env_colors[i];
  }

  return (
    colors
  )
}