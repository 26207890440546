import React, { useCallback, useEffect } from 'react';
import { StyleSheet, Dimensions, Modal, View } from 'react-native';

import FncColors from '../styles/colors';
let Colors = [];

import { Button } from './button';
import { H2, P} from '../components/text';

export default function Message(props) {
    Colors = FncColors();

    const content = props.content || { title: '', message: '', onClose: null };
    const visible = props.visible || false;
    const onPress = props.onPress || alert('een message moet een onPress functie hebben!');

    const close = () => {
        if( content.onClose ) content.onClose();
        onPress();
    }

  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      close();
    }
  }, []);
 
  useEffect(() => {
    document.addEventListener('keyup', handleEscKey, false);
 
    return () => {
      document.removeEventListener('keyup', handleEscKey, false);
    };
  }, [handleEscKey]);

    return (

      <Modal 
        animationType= "fade"
        visible={visible}
        transparent={true}
      >
        <View style={styles.overlay}>
            <H2 accessible={true}>{content.title}</H2>
            <P accessible={true}>{content.message}</P>
            <Button 
                arrowIcon={false} 
                color={Colors[7]} 
                bottom={0} 
                square={false} 
                fontSize={17} 
                fontFamily={'Semibold'} 
                textColor={Colors[9]} 
                height={46} 
                iconOrientation='none' 
                caption={'Sluiten'} 
                onPress={ close }
            />
        </View> 
      </Modal>

    )

}

// Style
const styles = StyleSheet.create({
  overlay: {
    backgroundColor: 'rgba(255,255,255, 1)',
    flex: 1,
    alignSelf: 'center',
    zIndex: 99999,
    position: "absolute",
    maxWidth: 1140,
    width: "50%",
    borderRadius: 25,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    paddingHorizontal: 30,
    paddingVertical: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: Dimensions.get('window').height * 0.1,
    top: 0,
    left: 0,
    right: 0,
  },
});