// Libraries
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { mobieleStyling as s } from '../styles/mobiel';

// components
import { P, H1, H2, H3 } from '../components/text';

export default function HarmonicaCard ( props ) { 
    Colors = FncColors();
    const item = props.item || { question: 'Dit is de vraag', answer:  'Dit is het antwoord' } ;
    const [ visible, setVisible ] = useState( false );

    return(
    <View  style={ styles.main }>
        <View style={ styles.box }>
            <View style = { { flex: 9 } }>
                <TouchableOpacity onPress={ () => { setVisible( !visible ) }} ><P>{ item.question }</P></TouchableOpacity>
            </View>
            <TouchableOpacity 
                style = { { flex: 1 } }
                onPress={ () => { setVisible( !visible ) } }>
                <FontAwesome name={ visible ? 'angle-double-left' : 'angle-double-down' } size={ 24 } color={ Colors[5] } />
            </TouchableOpacity>
        </View>
        <View style={s.AnswerBox}>
            { visible ? 
                <P color={ Colors[8] } > { item.answer} </P>
                : null
            }
        </View>
    </View>
    );
};


// Style
const styles = StyleSheet.create({
    main: {
        flexDirection: 'column', 
        flex: 1,
        alignItems: 'center', 
    },
    box: {
        flex: 10,
        flexDirection: 'row', 
        marginVertical: 10, 
        justifyContent: 'space-between', 
        borderBottomWidth: 2, 
        borderBottomColor: Colors[6],
    },
  });