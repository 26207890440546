/// Libraries
import React, {useState} from 'react';
import { View, Image, Dimensions} from "react-native";

// Styling 
import { mobieleStyling as s } from '../styles/mobiel';
import FncColors from '../styles/colors';
let Colors = [];

// Functions

// Components
import { PBold, P, SplitAlineas} from './text';
import { translate } from 'i18n-js';

// Variabele
export const TextBox = (props) => {
    Colors = FncColors();
    const tablet = props.tablet;

    const backgroundColor= props.backgroundColor || Colors[10]
    const height = props.height || 425;
    const paddingTop = props.paddingTop || 0;    
    
    return(
        <View style={{ padding: 15, flex: 1 }}>
            <View style={{ height: tablet ? height/2 : height, minHeight: 350, maxHeight: 400, backgroundColor: backgroundColor, borderRadius: 30, paddingHorizontal: 10, paddingTop: paddingTop}}>
                {props.children}
            </View>
        </View>
    )
}
export const TextBoxMobile = (props) => {
    Colors = FncColors();
    const backgroundColor = props.backgroundColor || Colors[10]
    const height = props.height || 425;
    
    return(
        <View style={{ paddingTop: 40, paddingHorizontal: 20 }}>
            <View style={{ height: height, backgroundColor: backgroundColor, borderRadius: 30, alignContent: 'center', alignSelf: 'center', paddingHorizontal: 10}}>
                {props.children}
            </View>
        </View>
    )
}
export const ImageTextBoxMobile = (props) => {
    Colors = FncColors();
    const backgroundColor= props.backgroundColor || colors[10]
    const imageSource = props.imageSource || 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.wearetravellers.nl%2Feuropa%2Fmooiste-plekken-europa%2F&psig=AOvVaw1V5tdX0MhQ-xR_z0M4W5-8&ust=1633702318495000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCJi9ocW9uPMCFQAAAAAdAAAAABAD';
    const height = props.height || 425;
    const description = props.description;
    
    return(
        <View style={{ alignSelf: 'center', borderColor: '#d3d3d3', borderWidth: 1, margin: 15, backgroundColor: backgroundColor, borderRadius: 30 }}>
            <Image accessible={true} accessibilityRole='image' accessibilityLabel={description} style={{ height: height* 0.4, borderTopLeftRadius: 30, borderTopRightRadius: 30 }} source={imageSource}/>
            <View style={{ paddingHorizontal: 5, minHeight: 450 }}>
                {props.children}
            </View>
        </View>
    )
}
export const ImageTextBox = (props) => {
    Colors = FncColors();
    const tablet = props.tablet;
    const backgroundColor= props.backgroundColor || colors[10]
    const imageSource = props.imageSource || 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.wearetravellers.nl%2Feuropa%2Fmooiste-plekken-europa%2F&psig=AOvVaw1V5tdX0MhQ-xR_z0M4W5-8&ust=1633702318495000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCJi9ocW9uPMCFQAAAAAdAAAAABAD';
    const height = props.height;
    const minHeight = props.minHeight;
    const button = props.button;
    const description = props.description;

    return(
        <View style={{ flex: 1, padding: 15, marginTop: tablet ? 5 : 0 }}>
            <View style={{ marginBottom: 10, flex: 1, borderColor: '#d3d3d3', borderWidth: 1, backgroundColor: backgroundColor, borderRadius: 30 }}>
                <Image accessible={true} accessibilityRole='image' accessibilityLabel={description}  style={{ height: height* 0.5, maxHeight: 250, borderTopLeftRadius: 30, borderTopRightRadius: 30, top: '-3.2vh'}} source={imageSource}/>
                <View style={{ paddingHorizontal: 15 }}>
                    {props.children}
                </View>
                {button}
            </View>
        </View>
    )
}

export const ImageOnlyBox = (props) => {
    Colors = FncColors();
    const tablet = props.tablet;
    const imageSource = props.imageSource || 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.wearetravellers.nl%2Feuropa%2Fmooiste-plekken-europa%2F&psig=AOvVaw1V5tdX0MhQ-xR_z0M4W5-8&ust=1633702318495000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCJi9ocW9uPMCFQAAAAAdAAAAABAD';
    const height = props.height || 425;
    const description = props.description;
    
    return(
        <View style={{ padding: 15, marginTop: tablet ? 5 : 0, flexWrap: 'wrap', flex: 1 }}>
            <View style={{ height: tablet ? height/2 : height, minHeight: 350, maxHeight: 400, width: "100%", alignSelf: 'center', borderRadius: 30 }}>
                <Image accessible={true} accessibilityRole='image' accessibilityLabel={description} style={{ flex: 1, borderRadius: 30}} source={imageSource}/>
            </View>
        </View>
    )
}