import React from 'react';
import { View, Text, StyleSheet, Image, Dimensions, ImageBackground, Button, TouchableOpacity } from 'react-native';

// Styles
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

const CardModule = ( { label, image, onPress } ) => {

  const { styles } = useStyles();

  return (
      <TouchableOpacity onPress={onPress} style={styles('card')}>
        <Image
          style={[styles('image'), {borderRadius: 8, borderWidth: 1}]}
          source={{ uri: image }}
        />
        <View style={styles('footer')}>
            <Text style={styles('text')}>{label}</Text>
          </View>
      </TouchableOpacity>
  )
} 

export default CardModule;

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      card: {
        width: '100%',
        marginBottom: 8,
      },
      image: {
        height: 225,
        width: '100%',
      },
      footer: {
        height: 25,
        top: 5,
      },
      text: {
        color: 'black',
        fontFamily: 'Regular',
        fontSize: 15,
        alignSelf: 'center',
      }
    },
    [minSize(DEVICE_SIZES.SMALL_DEVICE)] : {
      col: {
        width: '100%'
      },
      card: {
        width: '49.5%',
        marginBottom: 8,
      }
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      col: {
        width: '48.5%'
      },
      card: {
        width: '33%',
        marginBottom: 8,
      }
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
      col: {
        width: '32%'
      },
      card: {
        width: '24.5%',
        marginBottom: 8,
      },
    }
  }
)