// Libraries
import React from 'react';
import { StyleSheet, Dimensions, View, ImageBackground } from 'react-native';

// Styles

// Variables

// Default function
export default function TopContainer( props ) {
  const style = props.style || styles;
  const flex = props.flex || 10;
  const mobile = props.mobile;
  const backgroundImage = props.backgroundImage || null;

  if(mobile) {
    return(
      <View>
        { props.children}
      </View>
    )
  }

  return (
    <View style={ [style.top ] }>
      <ImageBackground source={ backgroundImage } style={styles.image}>
          { props.children }
      </ImageBackground>
    </View>
  );
}

// Style
const styles = StyleSheet.create({
  top: {
    flexDirection: 'column',
    width: "100%",
    height: "100vh",
  },
image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
  },
});