// Libraries
import React, {Component} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Functions
export const setData = async (key, value) => {
  try {
    const jsonValue = await JSON.stringify(value)
    await AsyncStorage.setItem(key, jsonValue)
  } catch (e) {
    console.log('error in storeData', e);
  }
}

export const getData = async (key, defaultJson) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key)
    return jsonValue != null ? await JSON.parse(jsonValue) : defaultJson;
  } catch(e) {
    console.log('error in getData', e);
  }
}

export const removeData = async (key) => {
  try {
    await AsyncStorage.removeItem(key)
  } catch (e) {
    console.log('error in resetData', e);
  }
}
