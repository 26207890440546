// Libraries
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, TouchableWithoutFeedback, Dimensions, Switch as RNSwitch, Text, View, Platform } from 'react-native';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';


// Styles
import FncColors from '../styles/colors';
let Colors = [];

// components
import { P, Label, H1, H2, H3, H4, Space } from '../components/text';
import { Table, Row, Cell } from '../components/table';
import IconSetter from './iconSetter';

//Default function
export default function socialButton( props ) {
  Colors = FncColors();
  const onPress = props.onPress || function () { console.log('maak onPress aan op social button') };
  const data = props.data || '';
  const mobile = props.mobile || false;

  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <TouchableOpacity 
      accessibilityRole='link'
      accessibilityLabel={ 'Een icoon van ' + data.icon_naam + ' met deze link ga je naar deze social media om je advies te delen'}
      style={[styles.socialsCircle, { marginHorizontal: (mobile ? 10 : 30), borderWidth: 1, borderColor: (isHovered ? Colors[9] : Colors[0]), transition: '0.3s' }]} 
      onPress={() => onPress()}
      ref={ref}
    >
      <IconSetter name={data.icon_naam} family={data.icon_familie}/>
    </TouchableOpacity>
  );
} 

const styles = StyleSheet.create({
  socialsCircle: {
    height: 50,
    width: 50,
    borderRadius: 50,
    backgroundColor: Colors[0],
    alignItems: 'center',
    justifyContent: 'center'
  },
});