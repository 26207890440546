// Libraries
import React from 'react';
import { StyleSheet, Text, View, ImageBackground, Image, Pressable, Dimensions } from 'react-native';

// Components
import placeholder from '../assets/pictos/default.png';
import { P, H1, H2, H3, Space, SplitAlineas } from '../components/text';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Variables

// functions

export const PictoBox = ( props ) => {
  Colors = FncColors();
  const source = props.source || placeholder;
  const width = props.width || 60;
  const height = props.height || 60;
  const mobile = props.mobile;
  const onPress = props.onPress;
  const action = props.action || false;


  return (
      <>
        { action ?
          <Pressable
            accessibilityLabel={global.settings.logo_accesability_description} 
            accessibilityRole='button'
            onPress={onPress}
            style={ mobile ? { width: 40, height: 40 } : { width: width, height: height } }
          >
            <Image
              source={ source } 
              style={ mobile ? {  width: global.settings.logo_mobile_width, height: global.settings.logo_mobile_height, marginTop: 25, marginLeft: 50 } : { width: width, height: height, resizeMode: 'contain' } }
            />          
          </Pressable>
        :
          <Image
            accessible={false}
            source={ source } 
            style={ mobile ? {  width: global.settings.logo_mobile_width, height: global.settings.logo_mobile_height, marginTop: 25, marginLeft: 50 } : { width: width, height: height, resizeMode: 'contain' } }
          />          
        }
      </>
  );
}
