// Libraries
import React, { useContext } from 'react';
import { StyleSheet, Dimensions, View } from 'react-native';

// Styling 
import FncColors from '../styles/colors';
let Colors = [];

// Context
import { GlobalContext } from '../context/globalState';

// Variables

// Default function
export default function BottomContainer( props ) {
  const commonContext = useContext(GlobalContext).commonState;
  const debug = commonContext.debug;
  Colors = FncColors();
  const style = props.style || styles;
  const flex = props.flex || 10;

  return (
    <View style={ [style.bottom ] }>
      { props.children }
    </View>
  );
}

// Style
const styles = StyleSheet.create({
  bottom: {
    flexDirection: 'column',
    width: Dimensions.get('window').width,
    backgroundColor: Colors[8],
  },
});