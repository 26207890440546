import Autocomplete from 'react-native-autocomplete-input';
import React, { useEffect, useState, useContext } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Platform,
} from 'react-native';

// Context
import { GlobalContext } from '../context/globalState';

// Styling 
import FncColors from '../styles/colors';
let Colors = [];

const AutocompleteInput = (props) => {
  Colors = FncColors();
  const commonContext = useContext(GlobalContext).commonState;
  const debug = commonContext.debug;
  const [allData, setAllData] = useState(null);
  const [query, setQuery] = useState('');
  const [reset, setSwitch] = useState(false);

  const antw_id = props.antwoordId;
  const maxWidth = props.maxWidth;
  const style = props.style;
  const autoFocus = props.autoFocus;
  const valueSetter = props.valueSetter;
  const placeholder = props.placeholder;
  const onSubmit = props.onSubmit;

  useEffect(() => {
    (async function fetchData() {
      try {
        if (query === ''){
          setAllData([{value: 0, label: ''}]);
          return;
        }
        const url = global.settings.api_url + "/autocomplete/" + antw_id + "?term=" + query + "&code=" + encodeURIComponent(global.settings.api_key);
        fetch(url)
        .then(response => response.json())
          .then(json => {
            // console.log('Data set');
            // console.log(json);
            setAllData(json);
        })
      } catch (err) {
        console.log(err);
      }
    })();
  }, [query.length > 3, reset]);

  return (
    <View style={{ width: maxWidth, borderColor: 'black', borderWidth: 1, borderRadius: global.settings.border_radius, paddingHorizontal: 1}}>
      <View>
      { allData ? <Autocomplete
      data={allData.length == 1 && allData[0].label === query ? [] : allData}
      containerStyle={[style, { borderRadius: global.settings.border_radius, borderColor: 'black'}]}
      onSubmitEditing={onSubmit}
      inputContainerStyle={{ height: 40, borderRadius: global.settings.border_radius, borderColor: 'transparent'}}
      autoFocus={autoFocus}
      placeholder={placeholder}
      value={query}
      onChangeText={(text) => setQuery(text)}
      listContainerStyle={allData.length == 1 && allData[0].label === query ? {} : { backgroundColor: Colors[11], borderColor: 'black', borderWidth: 1, zIndex: 9999, bottom: 5, borderBottomLeftRadius: global.settings.border_radius, borderBottomRightRadius: global.settings.border_radius, padding: 20}}
      flatListProps={{
        keyExtractor: (item, idx) => idx,
        renderItem: (item) => 
        <TouchableOpacity style={{backgroundColor: Colors[11], borderBottomLeftRadius: item.index === allData.length -1 ? global.settings.border_radius : 0, borderBottomRightRadius: item.index === allData.length -1 ? global.settings.border_radius : 0}} 
        onPress={() => [valueSetter(item.item.value), setQuery(item.item.label), setSwitch(!reset)]}>
          <Text style={{ fontFamily: 'Regular', fontSize: 16, color: Colors[9], lineHeight: 25}}>{item.item.label}</Text>
          </TouchableOpacity>,
      }}
    /> : <ActivityIndicator size={'large'} color={Colors[7]}/>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    borderColor: 'transparent',
    // Android requiers padding to avoid overlapping
    // with content and autocomplete
    paddingTop: 50,

    // Make space for the default top bar
    ...Platform.select({
      web: {
        marginTop: 0,
      },
      default: {
        marginTop: 25,
      },
    }),
  },
  autocompleteContainer: {
    // Hack required to make the autocomplete
    // work on Andrdoid
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
    padding: 5,
  },
});

export default AutocompleteInput;
