// Libraries
import React from 'react';
import { StyleSheet, Dimensions, View, Image } from 'react-native';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Variables

// Default function
export default function HeaderFrame( props ) {
  Colors = FncColors();
  // props uitbreiden met default values
  const def = {
    styles: styles,
  }
  props = {...def, ...props };

  // benodigde variabelen uit props halen
  const { styles } = props;
  const style = props.style || styler.headerframe;
  const mobile = props.mobile || false;


  return (
    <View style={style}>
      <View >
          { props.children }
      </View>
    </View>
  );
}

// Style
const styler = StyleSheet.create({
  headerframe: {
    /*height: 80,*/
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: Colors[0],
    marginBottom: 0,
    
  },
});

