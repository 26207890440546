// dit component is specifiek ontwikkeld voor de module KlimaatwijsOpVakantie
// als dit bij andere modules ook toegepast moete worden, is zowel aanpassing
// van het CMS als in deze module nodig


// Libraries
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { StyleSheet, Dimensions, View, ImageBackground, Text, Image } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import Checkbox from 'expo-checkbox';
import IconSetter from './iconSetter';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Functions
import { decideCSS, decideEnable, verwerkData, getKeyByValue, stripFirstColon, customConsoleLog, sendGa } from '../functions/common';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_PARAM, SET_PARAM_CACHE } from '../context/actions/commonActions';

// Components
import QuestionBar from '../components/questionBar';
import { P, H1, H2, H3, H4 } from '../components/text';
import { TextInputDefault } from '../components/textInput';
import DropDown, { CustomDropDown } from '../components/dropDown';
import RadioButton from '../components/radioButton';
import { Button } from './button';
import GooglePlacesInput from '../components/googlePlacesInput';
import Loader from '../components/loader';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Variables

var googleDone = false;

export default function CombiVragenContainer(props) {
  Colors = FncColors();
  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const debug = commonContext.debug;
  let param = commonContext.param;
  const param_cache = commonContext.param_cache;

  // console.log('Param:');
  // console.log(param);

  //console.log('Param Cache:');
  //console.log(param_cache);

  //Props
  const style = props.style || styles;
  const aom_data = props.aom_data;
  const loading = props.loading;
  const navigation = props.navigation;
  const mobile = props.mobile;
  const tablet = props.tablet;
  const module_naam = props.module || "onbekende_module";

  // Tijdelijke states
  const [firstDropdown, setFirstDropdown] = useState(false);
  const [secondDropdown, setSecondDropdown] = useState(false);
  const [thirdDropdown, setThirdDropdown] = useState(false);

  //Stap
  const stap = aom_data.stap;
  if (debug) console.log(stap);

  const vraagtype = stap.vraagtype;
  const stap_id = stap.sta_id;
  let inputAantal = stap.antwoorden.length;

  const bg_image =  require( '../assets/images/' + global.settings.image_folder + '/background_mobiel.jpeg' );

  let dependencies = [];
  let sizes = [];
  let icons = [];

  // {"name": "holiday-village", "family": "Fontisto"}, {"name": "bag-suitcase", "family": "MaterialCommunityIcons"},  Voor backoffice instellingen

  //Verwerkt de afhankelijkheden
  if(stap.sta_afhankelijkheden){
    const afhankelijkheden = JSON.parse(stap.sta_afhankelijkheden);
    if((typeof afhankelijkheden === "object")){
      if(afhankelijkheden.hasOwnProperty("dependencies")){
        dependencies = afhankelijkheden.dependencies;
      }

      if(afhankelijkheden.hasOwnProperty("sizes")){
        sizes = afhankelijkheden.sizes;
      }

      if(afhankelijkheden.hasOwnProperty("icons")){
        icons = afhankelijkheden.icons;
      }
    }
  }

  //States
  const [questionsDone, setQuestionsDone] = useState(false);
  const [inputData, setInputData] = useState({});
  const [statusData, setStatusData] = useState({});
  let [vervolgstap, setVervolgstap] = useState(stap.sta_vervolgstap_id);

  //Verwerk de chain uit de cache zodat je de inputs kan invullen waar nodig
  let newArrayAnt = [];
  let radioButton = {};
  let checkBox = [];
  let inputDataFilled = 0;
  const chain = param_cache.chain;
  if (debug) console.log(chain);
  if(chain){
    const objectIndex = chain.findIndex((obj => obj.stap_id == stap_id));
    if(objectIndex != "-1"){
      if(chain[objectIndex].hasOwnProperty("antwoord_id")){
        const antwoordIdArray = chain[objectIndex].antwoord_id;
        const type = chain[objectIndex].type;
        if(type == 'mc_multi'){//checkbox
          for(let i = 0; i < antwoordIdArray.length; i++){
              newArrayAnt[antwoordIdArray[i]] = 1;
              inputDataFilled++;
          }
        }
        else if(type == 'mc_enkel'){//radio button
          radioButton[antwoordIdArray] = 1;
          if (debug) console.log(radioButton);
          inputDataFilled++;
        }
        else{
          const antwoordArray = chain[objectIndex].antwoord;
          for(let i = 0; i < antwoordIdArray.length; i++){
            newArrayAnt[antwoordIdArray[i]] = antwoordArray[i];

            //Check specifiek op '', want de values uit de dependency maken we null
            if(antwoordArray[i] != '' && antwoordArray[i] != '0' ){
              inputDataFilled++;
            }
          }
        }
      }
    }
  }


  if (debug) console.log(newArrayAnt);

  //Koppel het type antwoord samen met het antwoord id in een array zodat je dit later kunt gebruiken
  let typeAntwoordId = {};
  stap.antwoorden.map((data, index) => {
    if(data.variabelenaam){
      typeAntwoordId[data.variabelenaam] = data.id;
    }

    //customConsoleLog(newArrayAnt[data.id]);

    if(!newArrayAnt[data.id] && newArrayAnt[data.id] !== null && newArrayAnt[data.id] != 0){
      //customConsoleLog("maak default");
      newArrayAnt[data.id] = data.default;
    }
  });

  //customConsoleLog(newArrayAnt);

  //Verwerk de content die is ingevuld/gekozen en zet dit in de State object
  const setContent = (value, type, antwoordId, vervolgstapId = null) => {
    if (debug) console.log(value, type, antwoordId, vervolgstapId);
    //Set de input data zodat je dit kan checken in de dependencies
    const newInputData = { ...inputData, [type]: value };
    setInputData(newInputData);

    //Vervolgstap id kan uit antwoord komen bij radio buttons
    if(vervolgstapId != null) {
      setVervolgstap(vervolgstapId);
      //customConsoleLog("vervolgstap: " + vervolgstapId);
    }

    let antwoord = null;

    //Pas aan de hand van het vraagtype aan wat je in het antwoord object stopt
    if(vraagtype == 'open_enkel'){
      //
    }
    else if(vraagtype == 'mc_enkel'){
      //
    }
    else if(vraagtype == 'mc_multi' || vraagtype == 'open_multi'){
      
      //Specifiek voor mc_multi (checkboxes)
      if(vraagtype == 'mc_multi'){
        //Als de checkbox ge-checked is, dan word hij nu dus ge-unchecked, value 0 maken
        if(value == 1){
          value = 0;
        }
        else{
          value = 1;
        }
      }

      antwoord = value;
    }
    else{
      alert("Vraagtype niet gevonden");
      return false;
    }
    let antObject = {[antwoordId]: antwoord};

    //Vewerk de dependencies ook in de antwoorden
    const newAntObject = checkDependency(type, newInputData, antObject);
    if (debug) console.log("newAntObject: " + JSON.stringify(newAntObject));
    // customConsoleLog("newAntObject:" + JSON.stringify(newAntObject));
    
    //Sla de data op in de asyncStorage en in de commonContext 
    verwerkData(param_cache, stap_id, vraagtype, newAntObject, commonDispatch, null, null, stap.sta_id === 1145 ? true : false);

    // console.log("inputData: " + JSON.stringify(newInputData));
  }

  /*
   * Loop door de dependencies heen en check of een type overeenkomt met een stap, 
   * als dat zo is dan het verwerken met execDependency.
  */
  const checkDependency = (type, newInputData, antObject) => {
    let values = [];
    let executedDepedency = null;
    if(dependencies){      
      dependencies.map((dependency) => {
        if(type == dependency.als_stap){
          let value = newInputData[type];
          if(!value){
            value = '';
          }
          executedDepedency = execDependency(dependency, value, antObject);
        }
      });
    }

    //Voor als het type niet voorkomt in de dependencies
    if(executedDepedency == null){
      executedDepedency = antObject;
    }
    return executedDepedency;
  }

  //Vewerk de dependency waarvan het type gelijk is aan de als_stap en pas daarbij de stap 
  // aan die word meegegeven.
  const execDependency = (dependency, val, antObject) => {
    if (debug) console.log("dependency: " + JSON.stringify(dependency));
    if(dependency.in.includes(parseInt(val))){
      //customConsoleLog("val word gevonden");
      if (debug) console.log('Value word gevonden');
      if(dependency.status == "invisible"){
        if(antObject != null){
          const antwoordId = typeAntwoordId[dependency.stap];
          let antwoord = dependency.value;
          if(antwoord == ''){
            //Maak de waarde null zodat je kan checken hoeveel er echt zijn ingevuld en welke niet perse nodig zijn om de knop te enablen om verder te gaan
            antwoord = null;
          }
          antObject[antwoordId] = antwoord;
        }
        //Werk status altijd bij
      }

      if(dependency.status == "visible"){
        //Werk status altijd bij
      }

      if(dependency.status == "enabled"){
        //Werk status altijd bij
      }

      if(dependency.status == "disabled"){
        if(antObject != null){
          const antwoordId = typeAntwoordId[dependency.stap];
          let antwoord = dependency.value;
          if(antwoord == ''){
            //Maak de waarde null zodat je kan checken hoeveel er echt zijn ingevuld en welke niet perse nodig zijn om de knop te enablen om verder te gaan
            antwoord = null;
          }
          antObject[antwoordId] = antwoord;
        }

        //Werk status altijd bij
      }

      //Werk status altijd bij
      if(antObject == null){
        const newStatus = statusData;
        newStatus[dependency.stap] = dependency.status;
      }
    }
    else {
      if(dependency.status == "invisible"){
        if(antObject == null){
          const newStatus = statusData;
          newStatus[dependency.stap] = "visible";
        }
        else{
          //Values weer resetten als het niet op de waarde staat, alleen als het null is
          const antwoordId = typeAntwoordId[dependency.stap];
          let antwoord = dependency.value;
          //console.log(antObject[antwoordId]);
          if(newArrayAnt[antwoordId] == null){
            antObject[antwoordId] = antwoord;
          }
        }
      }

      if(dependency.status == "visible"){
        if(antObject == null){
          const newStatus = statusData;
          newStatus[dependency.stap] = "invisible";
        }
      }

      if(dependency.status == "enabled"){
        if(antObject == null){
          const newStatus = statusData;
          newStatus[dependency.stap] = "disabled";
        }
      }

      if(dependency.status == "disabled"){
        if(antObject == null){
          const newStatus = statusData;
          newStatus[dependency.stap] = "enabled";
        }
        else{
          //Values weer resetten als het niet op de waarde staat, alleen als het null is
          const antwoordId = typeAntwoordId[dependency.stap];
          let antwoord = dependency.value;
          if(newArrayAnt[antwoordId] == null){
            antObject[antwoordId] = antwoord;
          }
        }
      }
    }
    //customConsoleLog("nieuwe status: " + JSON.stringify(statusData));

    //customConsoleLog(antObject);
    return antObject;
  }

  //Status verversen als de container refreshed, voor bijvoorbeeld de eerste keer als de pagina laad
  newArrayAnt.map((data, key) => {
    const type = getKeyByValue(typeAntwoordId, key);
    
    //customConsoleLog(key);
    dependencies.map((dependency) => {
      if(type == dependency.als_stap){
        //customConsoleLog(newInputData);

        let value = newArrayAnt[key];
        //customConsoleLog(value);
        if(!value){
          value = '';
        }
        execDependency(dependency, value, null);
      }
    });
  });

  const nextScreen = () => {
    //Pas de vervolgstap aan en voeg een deel aan de chain toe vanuit de param_cache
    if (debug) console.log(param);
    param.goto = vervolgstap;
    param.chain = [param_cache.chain[0]];

    const objectIndex = chain.findIndex((obj => obj.stap_id == stap_id));
    const add_chain = chain[objectIndex];
    const add_vervolg_chain = {...add_chain, vervolgstapId: vervolgstap }

    if (add_chain != null) {
      param.chain = [add_vervolg_chain];
    }

    //console.log(param);

    if (debug) console.log("volledige param: " + JSON.stringify(param));

    //Sla de param op zodat je dit bij het verversen van het scherm kan ophalen
    commonDispatch({ type: SET_PARAM, value: param });  
    navigation.push('Main', { module: module_naam });
  }

  /*
  const updateRadioButton = (volgnummer) => {
    //customConsoleLog("volgnummer: " + volgnummer);

    const newRadioData = {[volgnummer]:1};
    customConsoleLog(newRadioData);
    setRadioButton(newRadioData);
  }

  const updateCheckbox = (volgnummer) => {
    //customConsoleLog("volgnummer: " + volgnummer);
    let value = 1;
    const bestaand = checked[volgnummer];
    if(bestaand == 1){
      value = 0;
    }

    const newCheckedData = {...checked, [volgnummer]:value};
    customConsoleLog(newCheckedData);
    setChecked(newCheckedData);
  }*/

  if(!googleDone){
    googleDone = true;
    sendGa(module_naam, stap.pagina_tag);

    if (debug) console.log('google analytics startvragen', stap.pagina_tag);    
  }

  useEffect(() => {
    if(PerformanceNavigationTiming.type === 'back_forward'){
      if (debug) console.log('This page was accessed by navigating into the history');
      let param = {
        "goto":0,
        "chain":[]
      };
      commonDispatch({ type: SET_PARAM, value: param });    
      navigation.push('Main');
    }; 
  },[]);

  return useMemo(() => {
    //Als het radiobuttons of checkboxes zijn dan is 1 aangeklikt veld al genoeg om de knop te activeren
    if(vraagtype == "mc_enkel" || vraagtype == "mc_multi"){
      inputAantal = 1;
    }

    if (loading) {
      return <Loader />;
    }
    else {
      return (
        <View>
          { mobile == true ?
            <View style={{backgroundColor: Colors[0]}}>
              <Image source={ bg_image } style={{ width: "100%", height: 150, marginBottom: 30}}/>
            </View>
          :
            //niks
            null
          }

          <QuestionBar
            inputsContent={
              stap.antwoorden.map((data, index) => {
                //Voeg aan de hand van het type verschillende components toe
                if (data.input_type == "dropdown") {
                  const dropdown = data.dropdown;
                  const dropdown_array = dropdown.split("\r\n");

                  //Vul een array met alle dropdown values en voeg de placeholder aan het begin daar aan toe
                  const dropdown_values = dropdown_array.map((data) => {
                    return { label: data.split(":")[1], value: data.split(":")[0] };
                  });

                  let full_dropdown_array = dropdown_values;
                  if(data.placeholder != "" && data.placeholder != null){
                    full_dropdown_array = [{ label: data.placeholder, value: 0 }, ...dropdown_values];
                  }
                  if(index === 5){
                    if (debug) console.log(dropdown_values);
                  }

                  let first_dropdown_option = full_dropdown_array[0];//Placeholder
                  const new_label = stripFirstColon(first_dropdown_option.label);
                  first_dropdown_option.label = new_label;
                  //const first_dropdown_option = full_dropdown_array.shift();
                  //customConsoleLog(first_dropdown_option);

                  return (
                    {
                      icon: icons[index],
                      size: sizes[index],
                      titel: <View>
                      <H4 accessible={true} lineHeight={tablet ? 18 : mobile ? 22 : 24} maxWidth={ tablet ? '100%' : '100%'} fontSize={tablet ? 12 : mobile ? 16 : 18} color={ inputDataFilled < 3 && index > 2 ? '#cfcfcf' : decideEnable(data.variabelenaam, statusData) ? Colors[9]: '#cfcfcf'}>{data.titel}</H4>
                      </View>,
                      input: <CustomDropDown
                        tablet={tablet}
                        flex={sizes[index]}
                        onPressArrow={() => { index === 0 ? setFirstDropdown(!firstDropdown) : index === 2 ? setSecondDropdown(!secondDropdown) : setThirdDropdown(!thirdDropdown)}}
                        shifted={first_dropdown_option}
                        borderColor={{borderColor: inputDataFilled < 3 && index > 2 ? '#cfcfcf' : decideEnable(data.variabelenaam, statusData) ? Colors[9]: '#cfcfcf'}}
                        opties={full_dropdown_array}
                        value={newArrayAnt[data.id]}
                        enabled={inputDataFilled < 3 && index > 2 ? false : decideEnable(data.variabelenaam, statusData)}
                        onPress= {(value) => setContent(value, data.variabelenaam, data.id)}
                        mobile={mobile}
                        index={index}
                      />,
                      data: full_dropdown_array,
                      value: newArrayAnt[data.id],
                      onPressOption: (value) => index === 2 && value === '3' ? [setContent(value, data.variabelenaam, data.id), setTimeout(() => { setContent(6, 'stap_accommodatie', 4278)},[100])] : [setContent(value, data.variabelenaam, data.id)],
                      onPressArrow: () => index === 0 ? setFirstDropdown(!firstDropdown) : index === 2 ? setSecondDropdown(!secondDropdown) : setThirdDropdown(!thirdDropdown)
                    }
                  )
                }
                else if(data.input_type == "geo_autocomplete"){
                  return (
                    {
                      icon: icons[index],
                      size: sizes[index],
                      titel: <H4 accessible={true} lineHeight={tablet ? 18 : mobile ? 22 : 24} maxWidth={ tablet ? '100%' : '100%'} fontSize={tablet ? 12 : mobile ? 16 : 18} color={decideEnable(data.variabelenaam, statusData) ? Colors[9]: '#cfcfcf'} alignSelf={'left'}>{data.titel}</H4>,
                      input: <GooglePlacesInput
                        mobile={mobile}
                        tablet={tablet}
                        borderColor={{borderColor: inputDataFilled < 3 && index > 2 ? '#cfcfcf' : decideEnable(data.variabelenaam, statusData) ? Colors[9]: '#cfcfcf'}}
                        stap={stap.sta_id}
                        placeholder={data.placeholder}
                        onPress={(geo_data, geo_details = null) => {
                          setContent(geo_data.description, data.variabelenaam, data.id);
                        }}
                        setText={newArrayAnt[data.id]}
                        //onChangeText={(value) => customConsoleLog(value)}//Voor als je de input leeg maakt
                        style={[styles.googleInput, decideCSS(data.variabelenaam, statusData)]}
                        enable={decideEnable(data.variabelenaam, statusData)}
                      />
                    }
                  )
                }
                else if(data.input_type == "number" || data.input_type == "text" || data.input_type == "date" || data.input_type == "autocomplete" || data.input_type == "memo"){
                  return (
                    {
                      icon: icons[index],
                      size: sizes[index],
                      titel: <H4 accessible={true} maxWidth={'100%'} lineHeight={tablet ? 18 : mobile ? 22 : 24} fontSize={tablet ? 12 : mobile ? 16 : 18} color={inputDataFilled < 3 && index > 2 ? '#cfcfcf' : decideEnable(data.variabelenaam, statusData) ? Colors[9]: '#cfcfcf'} alignSelf={'left'}>{data.titel}</H4>,
                      input: <TextInputDefault
                        tablet={tablet}
                        placeholder={data.placeholder}
                        style={[styles.inputHome,{fontSize: (tablet ? 12 : mobile ? 16 : 15), borderColor: inputDataFilled < 3 && index > 2 ? '#cfcfcf' : decideEnable(data.variabelenaam, statusData) ? Colors[9]: '#cfcfcf', borderBottomWidth: mobile ? 0 : 1,
                        borderRadius: mobile ? 0 : 5
                      }, decideCSS(data.variabelenaam, statusData)]}
                        input={newArrayAnt[data.id]}
                        onChangeText={(value) => setContent(value, data.variabelenaam, data.id)}
                        //onBlur={(value) => blurred()}
                        placeholderTextColor={inputDataFilled < 3 && index > 2 ? '#cfcfcf' : Colors[9]}
                        returnKeyType="next"
                        editable={inputDataFilled < 3 && index > 2 ? false :decideEnable(data.variabelenaam, statusData)}
                        pattern={(data.input_type == "number" ? /^[0-9]+$/ : '')}//Aan de hand van het type een regex meegeven
                      />
                    }
                  )
                }/*
                else if(data.input_type == "radio"){
                  return (
                    {
                      icon: icons[index],
                      size: sizes[index],
                      titel: <H4 color={Colors[9]} alignSelf={'left'}>{data.tekst}</H4>,
                      input: <RadioButton 
                              value={0}
                              checked={radioButton[data.volgnummer] === 1 ? 'checked' : 'unchecked'}
                              label={data.tekst}
                              onPress={() => { updateRadioButton(data.volgnummer), setContent(1, "enkel", data.id, data.vervolgstap)}}
                            />
                    }
                  )
                }
                else if(data.input_type == "check"){
                  return (
                    { 
                      icon: icons[index],
                      size: sizes[index], 
                      titel: <H4 color={Colors[9]} alignSelf={'left'}>{data.tekst}</H4>,
                      input: <Checkbox
                              style={styles.checkbox}
                              value={checked[data.volgnummer]}
                              onValueChange={() => { updateCheckbox(data.volgnummer), setContent(1, data.volgnummer, data.id)}}
                              color={checked[data.volgnummer] ? '#4630EB' : undefined}
                            />
                    }
                  )
                }*/
                else{
                  return (
                    { 
                      titel: <H4 accessible={true} fontSize={tablet ? 12 : 17} color={Colors[9]} alignSelf={'left'}>Type niet gevonden</H4>,
                    }
                  )
                }
              })
            }
            mobile={mobile}
            tablet={tablet}
            buttonContent={'Toon klimaatimpact en advies'}
            inputDataFilled={inputDataFilled}
            inputAantal={inputAantal}
            onPress={() => nextScreen()}
            onPressArrow = {() => {index === 0 ? setFirstDropdown(!firstDropdown) : index === 2 ? setSecondDropdown(!secondDropdown) : setThirdDropdown(!thirdDropdown)}}
            firstDropdown={firstDropdown}
            secondDropdown={secondDropdown}
            thirdDropdown={thirdDropdown}
            setFirstDropdown={setFirstDropdown}
            setSecondDropdown={setSecondDropdown}
            setThirdDropdown={setThirdDropdown}
          />
        </View>
      );
    }
  }, [param_cache, newArrayAnt,])//Voeg de states toe zodat ze ook worden opgeslagen als er wat gewijzigd word in de inputs
}



// Style
const styles = StyleSheet.create({
  inputHome: {
    width: "40%",
    marginTop: 2,
    paddingTop: 2,
    paddingBottom: 4,
    paddingLeft: 3,
    paddingRight: 6,
    alignSelf: 'left',
    color: Colors[9],
    alignItems: 'center',
    justifyContent:'center'
  },
  googleInput: { 
    height: 30,
    padding: 3,
    fontSize: 15,
    marginLeft: -7,
    color: Colors[4],
    backgroundColor: 'rgba(100, 100, 100, 0.0)',
    zIndex: 100
  },
  checkbox: {
    color: Colors[9],
  }
});