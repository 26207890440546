import React, { useEffect } from "react";
import { ActivityIndicator, StyleSheet, View, Text, Image } from "react-native";

import FncColors from '../styles/colors';
let Colors = [];


export default function Loader( props ) {
  Colors = FncColors();

  const loader = '<img aria-hidden="true" src="' + require('../assets/loader.gif') + '" style="width: 60px;"/>'
  //const loader = '<img alt="Laden..." role="alert" aria-label="Laden..." aria-hidden="true" src="' + require('../assets/loader.gif') + '" style="width: 50px;"/>'

  useEffect(() =>{
    //setTimeout(function(){
      var loader_message = document.getElementById('loading-melding');
      loader_message.style.display = "block";

      console.log(loader_message.style.display);

        setTimeout(function(){
          loader_message.style.display = "none";

        }, 2000);
      
    //}, 1000);
  },[]);

  return (
    <View style={[styles.container, styles.horizontal, {backgroundColor: Colors[12]}]} accessible={false}>
      <div style={{ margin: 'auto' }} aria-hidden="true" dangerouslySetInnerHTML={{ __html: loader }} />
    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center"
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10
  }
});
