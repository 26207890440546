// Libraries
import React, { useRef, useState, useContext } from 'react';
import { StyleSheet, Dimensions, View, ImageBackground, TouchableWithoutFeedback, TouchableOpacity, Text, Image } from 'react-native';
import { AntDesign, Entypo, Ionicons } from '@expo/vector-icons';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

// Functions
import { clickedOutsideView } from '../functions/common';

// Styling 
import FncColors from '../styles/colors';
let Colors = [];

// Context
import { GlobalContext } from '../context/globalState';

// Components
import { P, H1, H2, H3, H4, Space, PBold } from '../components/text';
import IconSetter from './iconSetter';
import { Button } from './button';

// Variables


// Default function
export default function QuestionBar(props) {
  Colors = FncColors();
  const commonContext = useContext(GlobalContext).commonState;
  const debug = commonContext.debug;
  const inputsContent = props.inputsContent || [{ size: 25, content: <P>input 1</P> }, { size: 25, content: <P>input 2</P> }, { size: 25, content: <P>input 3</P> }, { size: 13, content: <P>input 4</P> }, { size: 12, content: <P>input 5</P> }];
  const buttonContent = props.buttonContent || <H2>ButtonContent</H2>;
  const onPress = props.onPress;// || function () { alert('maak onPress aan op buttton') };
  const mobile = props.mobile;
  const tablet = props.tablet;

  // Tijdelijke oplossing
  const firstDropdown = props.firstDropdown;
  const secondDropdown = props.secondDropdown;
  const thirdDropdown = props.thirdDropdown;
  const setFirstDropdown = props.setFirstDropdown;
  const setSecondDropdown = props.setSecondDropdown;
  const setThirdDropdown = props.setThirdDropdown;
  const inputAantal = props.inputAantal;
  const inputDataFilled = props.inputDataFilled;

  const viewRef = useRef(null);
  const viewRefTwo = useRef(null);
  const viewRefThree = useRef(null);
  clickedOutsideView(viewRef, setFirstDropdown);
  clickedOutsideView(viewRefTwo, setSecondDropdown);
  clickedOutsideView(viewRefThree, setThirdDropdown);
  //console.log(inputDataFilled);

  //Knop instellingen
  let disableButton = true;
  let colorButton = '#ECECEC';
  let colorButtonText = '#cfcfcf';
  let borderColor = { borderColor: '#cfcfcf' };
  let hoverButton = styles.hover;
  let enviroment = 'aom-viewer-rn';
  if (inputDataFilled >= inputAantal) {
    disableButton = false;
    colorButton = Colors[3];
    colorButtonText = Colors[9];
    borderColor = { borderColor: Colors[9] };
    hoverButton = styles.allowHover;
  }

  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <View style={{ marginTop: 110}}>
      {mobile == true ?
        inputsContent.map((item, index) => {
          const border = (index === inputsContent.length - 1) ? 0 : 1;
          const disabledBool = item.input.props.enabled ?? item.input.props.enable ?? item.input.props.editable;
          let input_value = item.input.props.value ?? item.input.props.setText ?? item.input.props.input;
          if (input_value == 0) {
            input_value = '';
          }
          if (debug) console.log("input waarde: " + input_value);
          return (
            <View
              accessible={true}
              accessibilityLabel='In deze lijst worden een aantal vragen gesteld om je klimaat impact te berekenen er zijn 5 opties'
              style={{ flexDirection: 'row', width: Dimensions.get('screen').width, justifyContent: 'center', marginVertical: 20, }}
              key={index}
            >
              <View style={{ top: 8, paddingRight: 20 }}>
                <IconSetter family={item.icon.family} name={item.icon.name} size={25} color={disabledBool ? Colors[9] : '#cfcfcf'} />
              </View>
              <View style={{ flexDirection: 'row', borderBottomColor: Colors[9], borderBottomWidth: 1 }}>
                <View style={{ flexDirection: 'column', width: Dimensions.get('screen').width * 0.7 }}>
                  {input_value && disabledBool ? <AntDesign name="checkcircle" style={{ position: 'absolute', right: 0, top: -3 }} size={20} color={Colors[18]} /> : null}
                  {isHovered && !input_value ?
                    <View style={{ position: 'absolute', zIndex: 999 }}>
                      <Image source={require('../assets/images/littleModal.png')} style={{ height: 80, width: 200, bottom: 80, zIndex: -1 }} />
                      <View style={{ flexDirection: 'row', bottom: 133, left: 40 }}>
                        <Ionicons name="information-circle" style={{ marginTop: 2 }} size={11} color="red" />
                        <P fontSize={11} color="red" >{"Vraag is niet ingevuld"}</P>
                      </View>
                    </View>
                    :
                    null
                  }
                  {item.titel}
                  {item.input}
                </View>
              </View>
            </View>
          )
        })
        :
        <View>
          <View style={[styles.bar, { zIndex: isHovered ? 0 : 99, backgroundColor: Colors[0] }]}>
            <View accessible={true} accessibilityLabel='In deze bar worden een aantal vragen gesteld om je klimaat impact te berekenen er zijn 6 opties' style={styles.inputsArea}>
              {
                inputsContent.map((item, index) => {
                  const disabledBool = item.input.props.enabled ?? item.input.props.enable ?? item.input.props.editable;
                  const border = (index === 2 || index === 5) ? 0 : 1;
                  let input_value = item.input.props.value ?? item.input.props.setText ?? item.input.props.input;
                  if (input_value == 0) {
                    input_value = '';
                  }
                  if (index > 2) {
                    return;
                  }

                  //console.log(index);

                  return (
                    <View style={{
                      borderTopLeftRadius: (index === 0 ? 20 : 0),
                      borderBottomLeftRadius: (index === 0 ? 20 : 0),
                      borderTopRightRadius: (index === 2 ? 20 : 0),
                      borderBottomRightRadius: (index === 2 ? 20 : 0),
                      borderLeftWidth: index === 1 ? 1.5 : 0,
                      borderRightWidth: index === 1 ? 1.5 : 0,
                      flex: (item?.size ? item.size : 20),
                      height: 120,
                      width: tablet ? 230 : 300
                    }} key={index}>
                      <View style={
                        input_value ? {
                          flex: 1,
                          maxHeight: 400,
                          borderColor: Colors[9],
                          borderTopLeftRadius: (index === 0 ? 20 : 0),
                          borderBottomLeftRadius: (index === 0 ? 20 : 0),
                          borderTopRightRadius: (index === 2 ? 20 : 0),
                          borderBottomRightRadius: (index === 2 ? 20 : 0),
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 1,
                          backgroundColor: '#ECECEC'
                        }
                          :
                          disabledBool ? {
                            flex: (item?.size ? item.size : 20),
                            maxHeight: 400,
                            borderColor: Colors[9],
                            borderRadius: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: -1,
                            backgroundColor: '#FFFFF'
                          }
                            :
                            {
                              flex: (item?.size ? item.size : 20),
                              maxHeight: 400,
                              borderColor: Colors[9],
                              borderRadius: 20,
                              alignItems: 'center',
                              borderTopLeftRadius: (index === 0 ? 20 : 0),
                              borderBottomLeftRadius: (index === 0 ? 20 : 0),
                              borderTopRightRadius: (index === 2 ? 20 : 0),
                              borderBottomRightRadius: (index === 2 ? 20 : 0),
                              justifyContent: 'center',
                              zIndex: -1,
                              backgroundColor: '#ECECEC'
                            }
                      } key={index}>
                        {isHovered && !input_value && disabledBool ?
                          <View style={{ position: 'absolute', zIndex: 999 }}>
                            <Image accessible={true} accessibilityRole='image' accessibilityLabel='Dit is een WaarschuwingsWolkje wat betekend dat je geen waarde heb ingevuld' source={require('../assets/images/littleModal.png')} style={{ alignSelf: 'center', height: 80, width: 200, bottom: 80, zIndex: -1 }} />
                            <View style={{ flexDirection: 'row', bottom: 133, alignSelf: 'center' }}>
                              <Ionicons name="information-circle" size={'1.3vh, 1vw'} color="red" />
                              <P fontSize={'1.3vh, 1 vw'} color="red" >{"Vraag is niet ingevuld"}</P>
                            </View>
                          </View> : null}
                        {input_value && disabledBool === true ? <AntDesign accessible={true} accessibilityRole='image' accessibilityLabel='Dit is een groen Vinkje je heb een juiste waarde ingevuld' name="checkcircle" style={{ position: 'absolute', right: 10, top: -7 }} size={18} color={Colors[18]} /> : null}

                        <View style={{ paddingHorizontal: 20 }}>
                          <View style={{ zIndex: 9 }}>
                            {item?.icon
                              ?
                              <View style={{ flexDirection: 'row' }}>
                                <View style={{ alignItems: 'center', marginRight: 15 }}>
                                  <IconSetter family={item.icon.family} name={item.icon.name} size={tablet ? '2.5vw' : (index === 4 ? '1.8vw' : 22)} color={disabledBool === true ? Colors[9] : '#cfcfcf'} />
                                </View>
                                <View>
                                  {item.titel}
                                  <View style={{ marginTop: 5 }}>
                                    {item.input}
                                  </View>
                                </View>

                              </View>
                              :
                              <View></View>
                            }
                          </View>
                        </View>
                        {firstDropdown && index === 0 || secondDropdown && index === 2 ?
                          <View ref={index === 0 ? viewRef : viewRefTwo} style={{ width: '100%', position: 'absolute', top: '75%', left: 0, borderBottomRightRadius: 30, borderBottomLeftRadius: 30, borderTopWidth: 0 }}>
                            {item.data.map((option, index) => {
                              return (
                                <TouchableOpacity accessibilityLabel={option.label} accessibilityRole='menuitem' key={option.value} style={[(item.data.length === index + 1 && !mobile) ? { borderBottomLeftRadius: 30, borderBottomRightRadius: 30, paddingBottom: 12 } : null, option.value === item.value ? { backgroundColor: Colors[9] } : { backgroundColor: Colors[0] }, { paddingVertical: 7, width: "100%", paddingLeft: tablet ? 30 : (mobile ? 4 : (index == 0 ? 66 : 62)) }]} onPress={() => [item.onPressOption(option.value), item.onPressArrow()]}>
                                  <H4 fontFamily={'Regular'} fontSize={tablet ? 12 : mobile ? 14 : 14} textAlign='left' color={option.value === item.value ? Colors[0] : Colors[9]}>{option.label}</H4>
                                </TouchableOpacity>
                              )
                            })}
                          </View> : null}
                      </View>
                    </View>
                  )
                })
              }
            </View>
          </View>
          <View style={[styles.bar, { backgroundColor: Colors[0], marginTop: 40 }]}>
            <View accessible={true} accessibilityLabel='In deze bar worden een aantal vragen gesteld om je klimaat impact te berekenen er zijn 6 opties' style={styles.inputsArea}>
              {
                inputsContent.map((item, index) => {
                  const disabledBool = inputDataFilled < 3 ? false : item.input.props.enabled ?? item.input.props.enable ?? item.input.props.editable;
                  const border = (index === 3 || index === 5) ? 0 : 1;
                  let input_value = item.input.props.value ?? item.input.props.setText ?? item.input.props.input;
                  if (input_value == 0) {
                    input_value = '';
                  }
                  if (index <= 2 || index > 5) {
                    return;
                  }

                  //console.log(index);

                  return (
                    <View style={{
                      borderTopLeftRadius: (index === 3 ? 20 : 0),
                      borderBottomLeftRadius: (index === 3 ? 20 : 0),
                      borderTopRightRadius: (index === 5 ? 20 : 0),
                      borderBottomRightRadius: (index === 5 ? 20 : 0),
                      borderLeftWidth: index === 4 ? 1.5 : 0,
                      borderRightWidth: index === 4 ? 1.5 : 0,
                      flex: (item?.size ? item.size : 20),
                      height: 120,
                      width: tablet ? 230 : 300
                    }} key={index}>
                      <View style={
                        input_value ? {
                          flex: 1,
                          maxHeight: 400,
                          borderColor: Colors[9],
                          borderTopLeftRadius: (index === 3 ? 20 : 0),
                          borderBottomLeftRadius: (index === 3 ? 20 : 0),
                          borderTopRightRadius: (index === 5 ? 20 : 0),
                          borderBottomRightRadius: (index === 5 ? 20 : 0),
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 1,
                          backgroundColor: '#ECECEC'
                        }
                          :
                          disabledBool ? {
                            flex: (item?.size ? item.size : 20),
                            maxHeight: 400,
                            borderColor: Colors[9],
                            borderRadius: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: -1,
                            backgroundColor: '#FFFFF'
                          }
                            :
                            {
                              flex: (item?.size ? item.size : 20),
                              maxHeight: 400,
                              borderColor: Colors[9],
                              borderRadius: 20,
                              alignItems: 'center',
                              borderTopLeftRadius: (index === 3 ? 20 : 0),
                              borderBottomLeftRadius: (index === 3 ? 20 : 0),
                              borderTopRightRadius: (index === 5 ? 20 : 0),
                              borderBottomRightRadius: (index === 5 ? 20 : 0),
                              justifyContent: 'center',
                              zIndex: -1,
                              backgroundColor: '#ECECEC'
                            }
                      } key={index}>
                        {isHovered && !input_value && disabledBool ?
                          <View style={{ position: 'absolute', zIndex: 999 }}>
                            <Image accessible={true} accessibilityRole='image' accessibilityLabel='Dit is een WaarschuwingsWolkje wat betekend dat je geen waarde heb ingevuld' source={require('../assets/images/littleModal.png')} style={{ alignSelf: 'center', height: 80, width: 200, bottom: 80, zIndex: -1 }} />
                            <View style={{ flexDirection: 'row', bottom: 133, alignSelf: 'center' }}>
                              <Ionicons name="information-circle" size={'1.3vh, 1vw'} color="red" />
                              <P fontSize={'1.3vh, 1 vw'} color="red" >{"Vraag is niet ingevuld"}</P>
                            </View>
                          </View> : null}
                        {input_value && disabledBool === true ? <AntDesign accessible={true} accessibilityRole='image' accessibilityLabel='Dit is een groen Vinkje je heb een juiste waarde ingevuld' name="checkcircle" style={{ position: 'absolute', right: 10, top: -7 }} size={18} color={Colors[18]} /> : null}

                        <View style={{ paddingHorizontal: 20 }}>
                          <View style={{ zIndex: 9 }}>
                            {item?.icon
                              ?
                              <View style={{ flexDirection: 'row' }}>
                                <View style={{ alignItems: 'center', marginRight: 15 }}>
                                  <IconSetter family={item.icon.family} name={item.icon.name} size={tablet ? '2.5vw' : (index === 4 ? 20 : 22)} color={disabledBool === true ? Colors[9] : '#cfcfcf'} />
                                </View>
                                <View>
                                  {item.titel}
                                  <View style={{ marginTop: 5 }}>
                                    {item.input}
                                  </View>
                                </View>

                              </View>
                              :
                              <View></View>
                            }
                          </View>
                        </View>
                        {thirdDropdown && index === 3 ?
                          <View ref={viewRefThree} style={{ width: '100%', position: 'absolute', top: '80%', left: 0, borderBottomRightRadius: 30, borderBottomLeftRadius: 30, borderTopWidth: 0 }}>
                            {item.data.map((option, index) => {
                              return (
                                <TouchableOpacity accessibilityLabel={option.label} accessibilityRole='menuitem' key={option.value} style={[(item.data.length === index + 1 && !mobile) ? { borderBottomLeftRadius: 30, borderBottomRightRadius: 30, paddingBottom: 12 } : null, option.value === item.value ? { backgroundColor: Colors[9] } : { backgroundColor: Colors[0] }, { paddingVertical: 7, width: "100%", paddingLeft: tablet ? 30 : (mobile ? 4 : (index == 0 ? 66 : 62)) }]} onPress={() => [item.onPressOption(option.value), item.onPressArrow()]}>
                                  <H4 fontFamily={'Regular'} fontSize={tablet ? 12 : mobile ? 14 : 14} textAlign='left' color={option.value === item.value ? Colors[0] : Colors[9]}>{option.label}</H4>
                                </TouchableOpacity>
                              )
                            })}
                          </View> : null}
                      </View>
                    </View>
                  )
                })
              }
            </View>
          </View>
        </View>
      }

      {mobile == true ?
        <View>
          <Button mobile={mobile} caption={'Toon klimaatimpact en advies'} onPress={onPress} disabled={disableButton} iconOrientation='none' bottom={(enviroment == 'camperclub' ? 10 : -12)} color={colorButton} textColor={colorButtonText} border={true} />
        </View>
        :
        <View style={[styles.buttonArea, isHovered && hoverButton, { backgroundColor: colorButton, borderColor: (isHovered ? Colors[9] : (inputDataFilled >= inputAantal ? Colors[9] : "#cfcfcf")), transition: '0.3s' }]} ref={ref}>
          <TouchableWithoutFeedback accessibilityRole='link' accessibilityLabel={buttonContent + ' Als je deze link gebruikt gaat de test verder of kom je op het resultaat scherm.'} onPress={onPress} disabled={disableButton}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingVertical: 17, paddingLeft: (tablet ? 28 : 34), paddingRight: (tablet ? 28 : 24), borderRadius: 25 }}>
              <View style={{ paddingRight: 30 }}>
                <H4 accessible={false} maxWidth='100%' fontSize={tablet ? 18 : 18} color={colorButtonText}>{buttonContent}</H4>
              </View>
              <Entypo name="chevron-thin-right" style={{ position: 'absolute', margin: 0, top: '50%', transform: 'translateY(-50%)', right: 20 }} size={tablet ? 17 : 21} color={colorButtonText} />
            </View>
          </TouchableWithoutFeedback>
        </View>
      }
    </View>
  );
}

// Style
const styles = StyleSheet.create({
  bar: {
    top: -50,
    alignSelf: 'center',
    borderRadius: 20,
  },
  inputsArea: {
    flexDirection: 'row',
    height: '100%',
    borderRadius: 20,
  },
  buttonArea: {
    zIndex: -1,
    alignItems: 'center',
    alignSelf: 'center',
    borderWidth: 1,
    borderRadius: 25
  },
  hover: {
    cursor: 'not-allowed'
  },
  allowHover: {
    cursor: 'pointer'
  },
});