// Libraries
import React from 'react';

// components

// Functions

export const clean_received_text = ( tekst ) => {

  //tekst = decodeURI(tekst);
  //return tekst;

  tekst = tekst.replace(/\'/g, '`');
  tekst = tekst.replace(/&quot;/g, "\'");
  tekst = tekst.replace(/&lt;/g, '<');
  tekst = tekst.replace(/&gt;/g, '>');
  tekst = tekst.replace( /<br\/>/g, ' ');
  return tekst;
}


export const get_script = ( tekst, clean ) => {
    if (clean === true ) tekst = clean_received_text( tekst );

    var cleaned = tekst.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, function(){
        var script = arguments[1].replace(/(\r\n|\n|\r)/gm, "");
        run_script( script, 'googlePackagesLoaded' );
        return '';
    });

  return cleaned;
}

export const run_script = ( script, obj ) => {

  if (typeof window[obj] != "undefined") {
      var scriptElement = document.createElement('script');
      scriptElement.setAttribute('type', 'text/javascript');
      scriptElement.innerText =  script;
      document.body.append(scriptElement);
  }
  else{
    setTimeout( function(){ run_script( script, obj ); }, 100 );
  }
}

export const refresh_grafieken = () => {
  for(var i = 0; i < 50; i++){
    var fn = 'drawDashboard_' + i;
    if (typeof window[fn] === "function") { 
        window[fn]();
    }   
    else {
      return;
    }     
  }
}


export const get_verfijnen = ( tekst, verfijningen, index ) => {
    var verfijnen = [];
    var ul = '';

    var cleaned = tekst.replace(/<ul class='verfijnen'>*>([\s\S]*?)<\/ul>/gi, function(){
        ul = arguments[1].replace(/(\r\n|\n|\r)/gm, "");
        return '';
    });

    if ( ul ) {
      verfijnen = get_verfijn_items ( ul );
    }

    if (verfijnen.length == 0 && index == 0) verfijnen = verfijningen;

  return { tekst: cleaned, verfijnen: verfijnen };
}

const get_verfijn_items = ( ul ) => {
    var arr = [];

    var cleaned = ul.replace(/<li[^>]*>([\s\S]*?)<\/li>/gi, function(){
        arr.push( arguments[1] );
        return '';
    });

    var items = [];
    for( var i = 0; i < arr.length; i++ ){
      var li = arr[i];

      var item = {};
      item.code = get_verfijn_code( li );
      item.tekst = get_verfijn_tekst( li );
      item.status = get_verfijn_status( li );
      var icon = get_verfijn_icon( li );
      item.iconFamily = icon.family;
      item.iconName = icon.name;

      items.push( item );
    }
    return items;
}

const get_verfijn_code = ( li ) => {
  var tmp = li.split('verfijn(')[1];
  tmp = tmp.split(')')[0];
  return parseInt(tmp);
}

const get_verfijn_tekst = ( li ) => {
  var tmp = li.split('</a>')[0];
  tmp = tmp.split('>').pop();
  return tmp;
}

const get_verfijn_status = ( li ) => {
  var tmp = li.split('status=').pop();
  tmp = tmp.split('=')[0];
  return parseInt(tmp);
}


const get_verfijn_icon = ( li ) => {
  var tmp = li.split("='")[1];
  tmp = tmp.split("'")[0];

  tmp = tmp.split(".");

  return { family: tmp[0], name: tmp[1] };
}



