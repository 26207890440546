// Libraries
import React, { useState, useMemo, useContext, useCallback, useEffect } from 'react';
import { StyleSheet, Dimensions, View, Text, findNodeHandle, AccessibilityInfo, Modal } from 'react-native';

// Styling
import FncColors from '../styles/colors';
let Colors = [];

// Functions
import { textReplace, customConsoleLog, get_property, clearAppData } from '../functions/common';
import { setData } from '../functions/storage';

// Components
import HeaderFrame from '../components/headerFrame';
import Header from '../components/header';
import ModuleOverzichtContainer from '../components/moduleOverzichtContainer';
import InspiratieContainer from '../components/inspiratieContainer';
import TopContainer from '../components/topContainer';
import CombiVragenContainer from '../components/combiVragenContainer';
import StartContainer from '../components/startContainer';
import VraagContainer from '../components/vraagContainer';
import AdviesContainer from '../components/adviesContainer';
import FooterFrame from '../components/footerFrame';
import Footer from '../components/footer';
import { H2, P} from '../components/text';
import { Button } from './button';
import Message from '../components/message';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_MODULE, SET_PARAM, RESET_PARAM, SET_PARAM_CACHE, SET_DATA } from '../context/actions/commonActions';

// Variables


// deze container beoordeeld de response van de data
// en opent de benodigde container, afhankelijk van het staptype

export default function MainContainer(props) {
  Colors = FncColors();
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const aom_data = commonContext.data;
  const module_naam = commonContext.module;
  const debug = commonContext.debug;
  const [footerOverlay, setFooterOverlay] = useState(false);
  const [footerOverlayTekst, setFooterOverlayTekst] = useState('');
  const [alertOverlay, setAlertOverlay] = useState(false);
  let schermtype = aom_data.schermtype;
  if (schermtype == "start" && global.settings.inspiration_page == 1) schermtype = "inspiratie";

  //Props
  const navigation = props.navigation;
  const mobile = props.mobile;
  const tablet = props.tablet;

  const bg_image = require('../assets/images/' + global.settings.image_folder + '/background.jpeg');

  //Stap
  const stap = aom_data.stap;

  // console.log(stap);

  //Disclaimer
  const disclaimer_titel = global.settings.disclaimer_titel ? global.settings.disclaimer_titel : aom_data.module.mod_disclaimer_titel;
  const disclaimer_tekst = global.settings.disclaimer_inhoud ? global.settings.disclaimer_inhoud : aom_data.module.mod_disclaimer_tekst;
  const cookies_tekst = global.settings.cookies_inhoud;
  const cookies_titel = global.settings.cookies_titel;
  const toegankelijkheid_tekst = global.settings.toegankelijkheid_inhoud;
  const toegankelijkheid_titel = global.settings.toegankelijkheid_titel;

  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo.png');
  const imageLogo = require('../assets/images/' + global.settings.image_folder + '/logo_small.png');

  const data = {
    title: get_property(global.settings, "title", commonContext.data.module.mod_label),
    subtitle: get_property(global.settings, "subtitle", commonContext.data.module.mod_titel)
  }


  //Functie om je weer te redirecten naar het begin
  const backHome = () => {
    commonDispatch({ type: RESET_PARAM });
    // als debug, dan geheugen legen
    if( debug > 0 ){
      clearAppData( false );
      setData("debug", debug);
      setAlertOverlay( {title: 'Melding', message: 'De cache is geleegd, alleen debug status is behouden.', onClose: () => location.reload() } );

      //location.reload();
    }
    else {
      navigation.push('Main');
    }
  }

  const closeFooterOverlay = () => {
    setFooterOverlay(false);
  }

  const show_overlay_tekst = (tekst) => {
    setFooterOverlay(true);
    setFooterOverlayTekst(tekst);
  }


  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      closeFooterOverlay();
    }
  }, []);
 
  useEffect(() => {
    document.addEventListener('keyup', handleEscKey, false);

    return () => {
      document.removeEventListener('keyup', handleEscKey, false);
    };
  }, [handleEscKey]);



  return useMemo(() => {
    //console.log('render maincontainer');

    switch (schermtype) {
      case "overzicht": // scherm met alle modules
        return (
          <View style={[styles.main]}>
            {global.settings.show_header_overzicht ?
              <HeaderFrame mobile={mobile}>
                <Header
                  logo={{ source: (mobile ? imageLogo : Logo) }}
                  title="Overzicht adviestools"
                  subtitle="Praktisch over duurzaam"
                  mobile={mobile}
                  tablet={tablet}
                  onClick={() => backHome()}
                  action={false}
                  accessible={true}
                />
              </HeaderFrame>
              : null
            }

            <ModuleOverzichtContainer
              mobile={mobile}
              tablet={tablet}
              navigation={navigation}
              modules={aom_data.modules}
            />
            {global.settings.show_footer_overzicht ?
              <FooterFrame>
                <Footer
                  navigation={navigation}
                  logo={{ source: Logo }}
                  title={'Overzicht adviezen'}
                  subtitle={"Praktisch over duurzaam"}
                  mobile={mobile}
                  disclaimer={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{disclaimer_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: disclaimer_tekst }} /></View>
                    </View>
                  )}
                  cookies={() => show_overlay_tekst(
                    <View>
                    <H2 fontFamily={'Semibold'} alignSelf={'left'}>{cookies_titel}</H2>
                    <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: cookies_tekst }} /></View>
                  </View>
                  )}
                  toegankelijkheid={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{toegankelijkheid_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: toegankelijkheid_tekst }} /></View>
                    </View>
                  )}
                />
              </FooterFrame>
              : null}
              <Modal 
                animationType={global.settings.overlay_animation || "slide"}
                visible={footerOverlay}
                transparent={true}
              >
                <View  accessible={true} style={styles.footerOverlay}>
                  <P>{footerOverlayTekst}</P>
                  <Button 
                    arrowIcon={false} 
                    color={Colors[7]} 
                    square={global.settings.border_radius === 0 ? true : false} 
                    fontSize={17} fontFamily={'Semibold'} 
                    textColor={Colors[19] !== '' ? Colors[16] : Colors[9]} 
                    height={46} 
                    iconOrientation='none' 
                    caption={global.settings.text_to_upper_case == 0 ? 'Sluiten' : 'SLUITEN'} 
                    onPress={() => closeFooterOverlay()} 
                  />
                </View>
              </Modal>
              <Message
                visible={alertOverlay}
                content={alertOverlay}
                onPress={ () => { setAlertOverlay(false); }}
              />
          </View>
        );
        break;
      case "inspiratie": // een uitgebreider startscherm van waaruit de eerste vraag geopend kan worden
        return (
          <View style={[styles.main]}>
                        {global.settings.show_header_advies ?
              <HeaderFrame mobile={mobile}>
                <Header
                  logo={{ source: (mobile ? imageLogo : Logo) }}
                  title={data.title}
                  subtitle={data.subtitle}
                  mobile={mobile}
                  tablet={tablet}
                  shareAndClose={true}
                  onClick={() => backHome()}
                  action={false}
                  accessible={true}
                />
              </HeaderFrame>
              : null
            }
            <InspiratieContainer
              mobile={mobile}
              tablet={tablet}
              navigation={navigation}
              title={data.title}
              subtitle={data.subtitle}
              module={module_naam}
              disclaimer_titel={disclaimer_titel}
              disclaimer_tekst={disclaimer_tekst}
            />
            <Message
              visible={alertOverlay}
              content={alertOverlay}
              onPress={ () => { setAlertOverlay(false); }}
            />
          </View>
        )
        break;
      case "start": // het openingsscherm van een module met uitleg en startknop
        return (
          <View style={[styles.main]}>
            {global.settings.show_header_start ?
              <HeaderFrame mobile={mobile}>
                <Header
                  logo={{ source: (mobile ? imageLogo : Logo) }}
                  title={global.settings.text_to_upper_case == 0 ? data.title : data.title.toUpperCase()}
                  mobile={mobile}
                  tablet={tablet}
                  // backButton={true}
                  onClick={() => backHome()}
                  action={false}
                  accessible={true}
                />
              </HeaderFrame>
              : null
            }

            <StartContainer
              mobile={mobile}
              tablet={tablet}
              navigation={navigation}
              module={aom_data.module}
            />
            {global.settings.show_footer_start == 1 ?
              <FooterFrame>
                <Footer
                  navigation={props.navigation}
                  logo={{ source: Logo }}
                  title={data.title}
                  subtitle={data.subtitle}
                  mobile={mobile}
                  disclaimer={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{disclaimer_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: disclaimer_tekst }} /></View>
                    </View>
                  )}
                  cookies={() => show_overlay_tekst(
                    <View>
                    <H2 fontFamily={'Semibold'} alignSelf={'left'}>{cookies_titel}</H2>
                    <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: cookies_tekst }} /></View>
                  </View>
                  )}
                  toegankelijkheid={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{toegankelijkheid_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: toegankelijkheid_tekst }} /></View>
                    </View>
                  )}
                />
              </FooterFrame> : null}
              <Modal 
                animationType={global.settings.overlay_animation || "slide"}
                visible={footerOverlay}
                transparent={true}
              >
                <View accessible={true} style={styles.footerOverlay}>
                  <P>{footerOverlayTekst}</P>
                  <Button arrowIcon={false} color={Colors[7]} square={global.settings.border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[19] !== '' ? Colors[16] : Colors[9]} height={46} iconOrientation='none' caption={global.settings.text_to_upper_case == 0 ? 'Sluiten' : 'SLUITEN'} onPress={() => closeFooterOverlay()} />
                </View>
              </Modal>
              <Message
                visible={alertOverlay}
                content={alertOverlay}
                onPress={ () => { setAlertOverlay(false); }}
              />
          </View>
        )
        break;
      case "vraag": // scherm met vraag / vragen
        // case "gecombineerde_vragen": // specifiek gemaakt voor KlimaatwijsOpVakantie
        return (
          <View style={[styles.main]}>
            {global.settings.show_header_vraag ?
              <HeaderFrame mobile={mobile}>
                <Header
                  logo={{ source: (mobile ? imageLogo : Logo) }}
                  title={global.settings.text_to_upper_case == 0 ? data.title : data.title.toUpperCase()}
                  subtitle={data.subtitle}
                  mobile={mobile}
                  tablet={tablet}
                  shareAndClose={true}
                  onClick={() => backHome()}
                  action={false}
                  accessible={false}
                />
              </HeaderFrame>
              : null
            }

            <VraagContainer
              mobile={mobile}
              tablet={tablet}
              navigation={navigation}
              aom_data={aom_data}
            />
            {global.settings.show_footer_vraag == 1 ?
              <FooterFrame>
                <Footer
                  navigation={navigation}
                  logo={{ source: Logo }}
                  align={'flex-start'}
                  title={data.title}
                  subtitle={data.subtitle}
                  mobile={mobile}
                  disclaimer={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{disclaimer_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: disclaimer_tekst }} /></View>
                    </View>
                  )}
                  cookies={() => show_overlay_tekst(
                    <View>
                    <H2 fontFamily={'Semibold'} alignSelf={'left'}>{cookies_titel}</H2>
                    <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: cookies_tekst }} /></View>
                  </View>
                  )}
                  toegankelijkheid={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{toegankelijkheid_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: toegankelijkheid_tekst }} /></View>
                    </View>
                  )}
                />
              </FooterFrame> : null}
              <Modal 
                animationType={global.settings.overlay_animation || "slide"}
                visible={footerOverlay}
                transparent={true}
              >
                <View accessible={true} style={styles.footerOverlay}>
                  <P>{footerOverlayTekst}</P>
                  <Button arrowIcon={false} color={Colors[7]} square={global.settings.border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[19] !== '' ? Colors[16] : Colors[9]} height={46} iconOrientation='none' caption={global.settings.text_to_upper_case == 0 ? 'Sluiten' : 'SLUITEN'} onPress={() => closeFooterOverlay(false)} />
                </View>
              </Modal>
              <Message
                visible={alertOverlay}
                content={alertOverlay}
                onPress={ () => { setAlertOverlay(false); }}
              />
          </View>
        )
        break;
      case "gecombineerde_vragen": // specifiek gemaakt voor KlimaatwijsOpVakantie
        return (
          <View style={[styles.main]}>
            {global.settings.show_header_advies ?
              <HeaderFrame mobile={mobile}>
                <Header
                  logo={{ source: (mobile ? imageLogo : Logo) }}
                  title={data.title}
                  subtitle={data.subtitle}
                  mobile={mobile}
                  tablet={tablet}
                  shareAndClose={true}
                  onClick={() => backHome()}
                  action={false}
                  accessible={true}
                />
              </HeaderFrame>
              : null
            }
            <TopContainer
              backgroundImage={bg_image}
              mobile={mobile}
              tablet={tablet}
            >
              <CombiVragenContainer
                aom_data={aom_data}
                mobile={mobile}
                tablet={tablet}
                navigation={navigation}
                module={module_naam}
              />
            </TopContainer>
            <Message
              visible={alertOverlay}
              content={alertOverlay}
              onPress={ () => { setAlertOverlay(false); }}
            />
          </View>
        )
        break;
      case "advies": // scherm met het advies
        post_message( JSON.stringify( aom_data.stap.stap_postmessage ) );
        return (
          <View style={[styles.main]}>
            {global.settings.show_header_advies ?
              <HeaderFrame mobile={mobile}>
                <Header
                  logo={{ source: (mobile ? imageLogo : Logo) }}
                  title={data.title}
                  subtitle={data.subtitle}
                  mobile={mobile}
                  tablet={tablet}
                  shareAndClose={true}
                  onClick={() => backHome()}
                  action={false}
                  accessible={false}
                />
              </HeaderFrame>
              : null
            }
            <AdviesContainer
              aom_data={aom_data}
              mobile={mobile}
              tablet={tablet}
              title={props.title}
              subtitle={props.subtitle}
              navigation={navigation}
              module={module_naam}
              disclaimer_titel={disclaimer_titel}
              disclaimer_tekst={disclaimer_tekst}
            />
            {global.settings.show_footer_advies == 1 ? 
              <FooterFrame>
                <Footer
                  navigation={props.navigation}
                  logo={{ source: Logo }}
                  title={data.title}
                  subtitle={data.subtitle}
                  mobile={mobile}
                  disclaimer={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{disclaimer_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: disclaimer_tekst }} /></View>
                    </View>
                  )}
                  cookies={() => show_overlay_tekst(
                    <View>
                    <H2 fontFamily={'Semibold'} alignSelf={'left'}>{cookies_titel}</H2>
                    <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: cookies_tekst }} /></View>
                  </View>
                  )}
                  toegankelijkheid={() => show_overlay_tekst(
                    <View>
                      <H2 fontFamily={'Semibold'} alignSelf={'left'}>{toegankelijkheid_titel}</H2>
                      <View accessible={true}><div style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: toegankelijkheid_tekst }} /></View>
                    </View>
                  )}
                />
              </FooterFrame> : null}
              <Modal 
                animationType={global.settings.overlay_animation || "slide"}
                visible={footerOverlay}
                transparent={true}
              >
                <View accessible={true} style={styles.footerOverlay}>
                  <P>{footerOverlayTekst}</P>
                  <Button arrowIcon={false} color={Colors[7]} square={global.settings.border_radius === 0 ? true : false} fontSize={17} fontFamily={'Semibold'} textColor={Colors[19] !== '' ? Colors[16] : Colors[9]} height={46} iconOrientation='none' caption={global.settings.text_to_upper_case == 0 ? 'Sluiten' : 'SLUITEN'} onPress={() => closeFooterOverlay()} />
                </View>
              </Modal>
              <Message
                visible={alertOverlay}
                content={alertOverlay}
                onPress={ () => { setAlertOverlay(false); }}
              />
          </View>
        )
        break;
      default:
        return <Text>Dit type scherm kennen we niet: {schermtype}</Text>
        break;
    }
  }, [footerOverlay, footerOverlayTekst, alertOverlay]);
}


// Style
const styles = StyleSheet.create({
  main: {
    flexDirection: 'column',
    width: "100%",
    minHeight: Dimensions.get('window').height,
    backgroundColor: '#ffffff',
  },
  footerOverlay: {
    backgroundColor: 'rgba(255,255,255, 1)',
    flex: 1,
    alignSelf: 'center',
    zIndex: 99999,
    maxHeight: 580,
    overflowY: 'scroll',
    position: "absolute",
    maxWidth: 1140,
    width: "100%",
    borderRadius: 25,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    paddingHorizontal: 30,
    paddingVertical: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: Dimensions.get('window').height * 0.05,
    top: 0,
    left: 0,
    right: 0,
  },
  adviesFooterOverlay: {
    backgroundColor: 'rgba(255,255,255, 1)',
    flex: 1,
    zIndex: 99999,
    maxWidth: 1140,
    position: "absolute",
    width: "100%",
    borderRadius: 25,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    paddingHorizontal: 30,
    paddingVertical: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    alignSelf: 'center',
    top: -730,
  }
});