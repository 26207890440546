import React from 'react';
import { Ionicons, MaterialIcons, FontAwesome5, AntDesign, EvilIcons, FontAwesome, Entypo, Fontisto, MaterialCommunityIcons, SimpleLineIcons, Feather, Foundation, Octicons, Zocial} from '@expo/vector-icons';

import FncColors from '../styles/colors';
let Colors = [];

//Fontello
import { createIconSetFromFontello } from '@expo/vector-icons';
import fontelloConfig from '../assets/fontello/config.json';
import fontelloAomIconsConfig from '../fonts/config.json';
import { useFonts } from 'expo-font';

export default function IconSetter (props) {
    Colors = FncColors();
    const family = props.family || null;
    const iconName = props.name;
    const size = props.size || 30;
    const color = props.color || Colors[9]

    if(family === 'MaterialCommunityIcons'){
        return (
            <MaterialCommunityIcons name={iconName} size={size} color={color}/>
        );
    };
    if(family === 'Fontisto'){
        return (
            <Fontisto name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Ionicons"){
        return(
            <Ionicons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "MaterialIcons"){
        return(
            <MaterialIcons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "FontAwesome5"){
        return(
            <FontAwesome5 name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Entypo"){
        return(
            <Entypo name={iconName} size={size} color={color}/>
        );
    };
    if(family === "AntDesign"){
        return(
            <AntDesign name={iconName} size={size} color={color}/>
        );
    };
    if(family === "FontAwesome"){
        return(
            <FontAwesome name={iconName} size={size} color={color}/>
        );
    };
    if(family === "EvilIcons"){
        return(
            <EvilIcons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Fontello"){
        //Stukje code om Fontello icons te gebruiken
        let fontello;
        const [loaded] = useFonts({
            fontello: require('../fonts/fontello.ttf'),
        });

        if(!loaded){
            return null;
        }

        const Icon = createIconSetFromFontello(fontelloConfig, 'fontello', fontello);
        return(
            <Icon name={iconName} size={size} color={color}/>
        )
    }
    if(family === 'FontelloKOV'){
        //Stukje code om Fontello icons te gebruiken
        let fontello;
        const [loaded] = useFonts({
            fontello: require('../fonts/aom-icons.ttf'),
        });

        if(!loaded){
            return null;
        }

        const Icon = createIconSetFromFontello(fontelloAomIconsConfig, 'fontello', fontello);
        return(
            <Icon name={iconName} size={size} color={color}/>
        )
    }

    if(family === "SimpleLineIcons"){
        return(
            <SimpleLineIcons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Feather"){
        return(
            <Feather name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Foundation"){
        return(
            <Foundation name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Octicons"){
        return(
            <Octicons name={iconName} size={size} color={color}/>
        );
    };
    if(family === "Zocial"){
        return(
            <Zocial name={iconName} size={size} color={color}/>
        );
    };


    return (
        null
    );

};