// Libraries
import React, { useRef, useState }  from 'react';
import { StyleSheet, Pressable, View, Text, Dimensions } from 'react-native';
import { AntDesign, FontAwesome5} from '@expo/vector-icons';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { mobieleStyling as s } from '../styles/mobiel';

// components
import { P, H1, H2, H4, Space} from '../components/text';
import IconSetter from './iconSetter';

// Variables
const buttonColor = Colors[5];

// Functions
export const Button = ( props ) => {
  Colors = FncColors();
  const onPress = props.onPress || console.log('maak onPress aan op buttton');
  const alignSelf = props.alignSelf || 'center';
  const paddingHorizontal = props.paddingHorizontal || 40;
  const height = props.height || 65;
  const bottom = props.bottom || 0;
  const caption = props.caption || 'caption';
  const textColor = props.textColor || Colors[0];
  const color = props.color || buttonColor;
  const iconOrientation = props.iconOrientation || 'right';
  const arrowIcon = props.arrowIcon || true; 
  const arrowColor = props.arrowColor || Colors[0];
  const customIcon = props.customIcon;
  const border = props.border || false;
  const borderColor = props.borderColor || Colors[9];
  const square = props.square;
  const mobile = props.mobile;
  const flex = props.flex || null;
  const textAlign = props.textAlign || 'left';
  const maxWidth = props.maxWidth;
  const buttonWidth = props.buttonWidth;
  const disabled = props.disabled;
  const fontSize = props.fontSize || 21;
  const fontFamily = props.fontFamily || 'Regular';
  const role = "button"; //props.role || "button";
  let fontAfwijking = false;
  if (global.settings.font_regular !== 'ProximaNova-Regular.otf') {
    fontAfwijking = true;
  }

  return (
      <Pressable
        accessibilityRole={role}
        style={[ styles.button, {flex: flex, backgroundColor: color, height: height, alignSelf: alignSelf, paddingHorizontal: paddingHorizontal, maxWidth: buttonWidth, borderRadius:  square ? 0 : 30,}, border ? { borderWidth: 1, borderColor: borderColor } : null ]}
        onPress={onPress}
        disabled={disabled}
      >
       <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {/* Dit is een arrow of custom icoon voor de linker kant van de knop */}
         {iconOrientation == 'left' && customIcon ? <>{customIcon}</> : null }
      {arrowIcon && iconOrientation === 'left' ? 
        <FontAwesome5 style={{ paddingRight: 10, paddingTop: 4 }} name="chevron-left" size={16} color={arrowColor}/> 
        : null}

        {/* Hier komt de text van de knop.*/}
        <View style={{ bottom: mobile ? -2 : bottom, maxWidth: maxWidth, marginTop: fontAfwijking ?  3 : 4,}}>
          <P fontWeight='700' accessible={false} fontSize={fontSize} fontFamily={fontFamily} textAlign={textAlign} color={ textColor }>{caption}</P>
        </View>
        {/* Dit is een arrow of custom icoon voor de rechter kant van de knop */}
        {iconOrientation == 'right' && customIcon ? <>{customIcon}</> : null }
        {arrowIcon && iconOrientation === 'right' ? 
        <FontAwesome5 style={{ paddingLeft: 10, paddingTop: 4, marginTop: global.settings.button_margin_helper }} name="chevron-right" size={16} color={arrowColor} /> 
        : null}

      </View>
    </Pressable>
  );
}

export const ButtonHome = (props) => {
  Colors = FncColors();
  const onPress = props.onPress || console.log('maak onPress aan op buttton');
  const caption = props.caption || 'caption';
  const textColor = props.textColor || Colors[0];
  const color = props.color || buttonColor;
  const textAlign = props.textAlign || 'left';
  const maxWidth = props.textWidth;
  const buttonWidth = props.buttonWidth;
  const tablet = props.tablet;
  const role = "button"; //props.role || "button";

  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
      <Pressable
        //accessibilityViewIsModal={true}
        //accessibilityLabel={caption}
        accessibilityRole={role}
        style={{ backgroundColor: color, borderRadius: 40, borderWidth: 1, borderColor: (isHovered ? Colors[9] : color)}}
        onPress={onPress}
        ref={ref}
      >
      <View style={{ flexDirection: 'row'}}>
        <View style={{ paddingBottom: 4, paddingLeft: 20, paddingRight: tablet ? 20 : 35, paddingTop:  tablet ? 20 : 15}}>
          <H4 fontSize={tablet ? 16 : 23} textAlign={textAlign} color={ textColor }>{caption}</H4>
        </View>
        <View style={{ paddingTop: tablet ? 20 : 16, paddingBottom: tablet ? 20 : 16, paddingRight: 20 }}>
          <FontAwesome5 name="chevron-right" size={tablet ? 16 : 19} color="black" />
        </View>
      </View>
    </Pressable>
  );
}

export const ButtonHomeMobile = (props) => {
  Colors = FncColors();
  const onPress = props.onPress || console.log('maak onPress aan op buttton');
  const height = props.height || 60;
  const bottom = props.bottom || 2;
  const caption = props.caption || 'caption';
  const textColor = props.textColor || Colors[0];
  const color = props.color || buttonColor;
  const textAlign = props.textAlign || 'left';
  const maxWidth = props.textWidth;
  const border = props.border;
  const borderColor = props.borderColor || Colors[9];
  const role = "button"; //props.role || "button";

  return (
      <Pressable
        //accessibilityViewIsModal={true}
        accessibilityRole={role}
        style={{ backgroundColor: color, borderRadius: 40, borderWidth: border ? 1 : 0, borderColor: borderColor, maxWidth: Dimensions.get('window').width > 400 ? '55%' : '80%'}}
        onPress={onPress}
      >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
        <View style={{ bottom: bottom, paddingLeft: 20, paddingVertical: 18}}>
          <H4 fontSize={21} textAlign={textAlign} color={ textColor }>{caption}</H4>
        </View>
        <View style={{ paddingTop: 18, paddingRight: 20, paddingLeft: 10 }}>
          <FontAwesome5 name="chevron-right" size={19} color="black" />
        </View>
      </View>
    </Pressable>
  );
}

export const ButtonVerfijn = ( props ) => {
  Colors = FncColors();
  const index = props.index;
  const mobile = props.mobile;
  const iconName = props.iconName || 'home';
  const iconFamily = props.iconFamily || 'Entypo';
  const title = props.title || 'title';
  const status = props.status || 0;
  const caption = props.caption || 'kies';
  const onPress = props.onPress || console.log('maak onPress aan op buttton');
  const borderColor = props.borderColor || Colors[0];
  const borderColorHover = props.borderColorHover || Colors[9];  
  const backgroundColor = props.backgroundColor || Colors[8];
  const backgroundColorHover = props.backgroundColorHover || Colors[8];
  const color = props.color || Colors[9];
  const colorHover = props.colorHover || Colors[9];
  const checkColor = props.checkColor || Colors[3];
  const checkColorHover = props.checkColorHover || Colors[3];
  const type = props.type || 1;
  const label = props.label || "";
  const clss = props.class || "";
  const text = props.text || "";
  const role = "button"; //props.role || "button";

  const ref = useRef(null);
  const isHovered = useHover(ref);

  const clickHandler = (e) => {
    //default acties uitzetten
    e.preventDefault();
    e.returnValue = false;
    onPress();
  };

  var html = '<div class="cta {class}" tabindex="0"><H2 role="heading" aria-level="2">{titel}</H2><p>{tekst}</p><a class="button {class}" href="JavaScript:void()" rel="noopener" target="" data-title="{label}">{label}</a></div>';
  html = html.replace( /{class}/g, clss );
  html = html.replace( /{titel}/g, title );
  html = html.replace( /{tekst}/g, text );
  html = html.replace( /{label}/g, label );

  return (
    <>
    { type == 2 ?
      <div onClick={clickHandler} style={{ fontFamily: "Regular", fontSize: 18, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: html }} />
    :
      <Pressable 
        accessibilityRole={role}
        style={{ 
          borderWidth: 1, 
          borderColor: (isHovered ? borderColorHover : borderColor), 
          transition: '0.3s', 
          width: (mobile ? '95%' : (clss == 'full_width' ? '99%' : '50%')), 
          height: (mobile ? 60 : 80), 
          borderRadius: 20, 
          backgroundColor: (isHovered ? backgroundColorHover : backgroundColor), 
          flexDirection: 'row', 
          alignSelf: (clss == 'left' ? 'flex-start' : (clss == 'right' ? 'flex-end' : 'center')), 
          alignItems: 'center', 
          paddingLeft: ( mobile ? 20 : 40),
          marginRight: 10,
          marginVertical: 15 
        }}
        onPress={ onPress } 
        ref={ ref } 
      >
        <View style={{ flexDirection: 'row' }}>
          <IconSetter 
            name={iconName} 
            family={iconFamily} 
            color={ (isHovered ? colorHover : color) } 
            size={ mobile ? 40 : 50} 
          />
          <View style={{ width: 20 }} />
          <View style={{ 
            paddingTop: 13, 
            paddingLeft: ( mobile ? index === 0 || index === 2 ? 12 : 1 : index === 0 ? 5 : index === 2 ? 12 : 5) 
          }}>
            <H4 
              color={ (isHovered ? colorHover : color ) } 
              fontSize={ mobile ? 16 : 25}
            >
              {title}
            </H4>
          </View>
        </View>
        {status == 1 ? 
          <AntDesign 
            name="checkcircle" 
            style={{
              position: 'absolute', 
              right: 16,
              top: 12
            }} 
            size={( mobile ? 20 : '2vw')} 
            color={ (isHovered ? checkColorHover : checkColor) } 
          /> 
          : null }
      </Pressable>
    }
    </>
  )
}

// Style
const styles = StyleSheet.create({
  buttonKies: {
    width: "15%",
    height: 40,
    borderRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
    backgroundColor: Colors[3],
  },
  buttonKiesMobile: {
    width: "30%",
    height: 40,
    borderRadius: 30,
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
    backgroundColor: Colors[3],
  },
  icon: {
    paddingHorizontal: 10,
    margin: 0,
  },
  button: {
    alignItems: "center",
    padding: 10,
    margin: 10,
  },
  slider: {
  },
  switch: {
    transform: [{ scaleX: 1 }, { scaleY: 1 }]
  },
  colorPicker: {
  },});