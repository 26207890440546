// Libraries
import React, { useContext, useState } from 'react';
import { View, Text, StyleSheet, ImageBackground, Dimensions, TouchableOpacity, Image} from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_PARAM, RESET_PARAM } from '../context/actions/commonActions';

// Components
import { P, H1, H2, H3, H4, Link } from '../components/text';
import { PictoBox } from '../components/images';
import { Button } from './button';
import { textReplace } from '../functions/common';

// Default component
export default function LeftContainer( props ) {
  const [htmlOverlay, setHtmlOverlay] = useState(false);
  const [html, setHtml] = useState('');

  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo_small.png');

  const title = props.title || 'Module titel';
  const subtitle = props.subtitle || '';
  const vraagNummer = props.vraagNummer;
  const vraagMax = props.vraagMax;
  const clickHandler = props.clickHandler;
  const image = props.image;
  const module = props.module;
  const mobile = props.mobile || false;
  const tablet = props.tablet;
  const { styles } = useStyles();

  // CookieData
  const [cookiesCaption, setCookiesCaption] = useState('');
  const [cookies, setCookies] = useState('');
  const [videoPlaceholder, setVideoPlaceholder]= useState('');
  const [videoTitle, setVideoTitle]= useState('');
  const [youtubeLink, setYoutubeLink]= useState('');

  let fontAfwijking = false;
  if (global.settings.font_regular !== 'ProximaNova-Regular.otf') {
    fontAfwijking = true;
  }

  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;

  const home = () => {
    commonDispatch({ type: RESET_PARAM });
    props.navigation.push( "Main", {module: module.mod_naam });
  }

  const opnieuwInvullen = () => {
    const param = { goto: "first", chain: [] };
    commonDispatch( { type: SET_PARAM, value: param } );
    props.navigation.push( "Main", {module: module.mod_naam });
  }

  return ( 
    <View style={{ backgroundColor: Colors[12], flex: Dimensions.get('window').width < 1000 ? 45 : 35, minHeight: Dimensions.get('window').height }}>
      <View style={{ padding: tablet ? 45 : Dimensions.get('window').width < 1000 ? 20 : 40, top: -20}}  onClick={clickHandler}>
      { vraagNummer && module.mod_teller_actief == 1 ? <View style={{ marginRight: 20, marginBottom: 10}}>
        <H4 fontFamily='Regular' accessible={true} fontSize={ tablet ? 16 : 18 }>{'Vraag ' + vraagNummer + '/' + vraagMax}</H4>
        </View> : null }
        <H4 fontWeight='700' accessible={true} realHeader={true} level="1" maxWidth={ Dimensions.get('window').width < 1000 ? '175%' : '100%' } fontSize={tablet ? 18 : 21} color={Colors[17] !== '' ? Colors[17] : Colors[2]}>{ title }</H4>
        { subtitle != '' ?
        <View accessible={true} style={{ marginVertical: 10 }}>
          <div style={{ fontFamily: "Regular", fontSize: tablet ? 16 : 18, lineHeight: '120%', color: Colors[9] }} dangerouslySetInnerHTML={{ __html: subtitle }} />
        </View>
        : null }
        {image ? 
        <View accessible={false} aria-hidden={true} style={{alignItems: 'flex-start', marginTop: 10}}>
          <img src={image} style={{ maxWidth: '100%', height: mobile ? 70 : 'auto'}}/>
          {/* <Image accessible style={styles('image')} source={{ uri: image}}/> */}
        </View>
        : null }
      </View>
    </View>
  );      
}

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      image: {
        height: 350,
        marginTop: 15,
        width: 350,
        resizeMode: "contain",
        alignSelf: 'flex-start'
      },
    },
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      image: {
        height: 240,
        marginTop: 15,
        width: 240,
        resizeMode: "contain",
        alignSelf: 'flex-start'
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      image: {
        height: 400,
        marginTop: 15,
        width: '100%',
        resizeMode: "contain",
        alignSelf: 'flex-start'
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
    }
  }
)