// Libraries
import React from 'react';
import { StyleSheet, Dimensions, View } from 'react-native';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Variables

// Default function
export default function FooterFrame( props ) {
  Colors = FncColors();
  // props uitbreiden met default values
  const def = {
    style: styles,
  }
  props = {...def, ...props };

  // benodigde variabelen uit props halen
  const { style } = props;

  return (

    <View style={style.navbarframe}>
        { props.children }
    </View>
  );
}

// Style
const styles = StyleSheet.create({
  navbarframe: {
    width: "100%",
    zIndex: -1,
    backgroundColor: Colors[0],
    marginTop: 0,
    paddingTop: 0,
  },  
});

