// Libraries
import React, { useState, useContext, useRef, forwardRef, createRef, useEffect } from 'react';
import { TextInput, Platform } from 'react-native';

// Components
import { P, H1, H2, H3 } from '../components/text';



export const TextInputDefault = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const input = props.input;
  const autoCapitalize = props.autoCapitalize || 'words';
  const returnKeyType = props.returnKeyType;
  const placeholder = props.placeholder;
  const placeholderTextColor = props.placeholderTextColor;
  const onClickOnInput = props.onClickOnInput;
  //const onValidation = props.onValidation;
  const onChangeText = props.onChangeText;
  const onBlurText = props.onBlur || console.log;
  const style = props.style;
  const keyboardType = props.keyboardType || null;
  const pattern = props.pattern;
  const onSubmit = props.onSubmit;
  const editable = props.editable;
  const selectTextOnFocus = (props.editable == false ? false : props.selectTextOnFocus);
  const autoFocus = props.autoFocus;

  const ref = createRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref && ref.current) {
        //onBlur(ref.current);
      }
    }

    // Bind the event listener
    document.addEventListener("focusout", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("focusout", handleClickOutside);
    };
  }, [ref]);

  const handleValidation = (value) => {
    if (!pattern) {
      return value;
    } else {
      if (pattern.test(value) || value === "") {
        return value
      }
    }
  }

  const onBlur = async ( value ) => {
    const result = await handleValidation(value);
    onBlurText( result );
  }

  const onChange = async (value) => {
    const result = await handleValidation(value);
    //console.log("result: " + result);

    onChangeText(result);
  }

  return (<>
    <TextInput
      ref={ref}
      autoFocus={autoFocus}
      style={style}
      returnKeyType={returnKeyType}
      autoCapitalize={autoCapitalize}
      pattern={pattern}
      keyboardType={keyboardType}
      autoCorrect={false}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor}
      value={input}
      onChangeText={value => onChange(value)}
      onSubmitEditing={onSubmit}
      editable={editable}
      selectTextOnFocus={selectTextOnFocus}
      onBlur={() => onBlur(ref.current.value)}
      autoComplete={ Platform.OS === 'web' ? 'none' : 'off' }
    />
    {error ? <P textAlign='center' color={'red'}>{errorMessage}</P> : null}
  </>
  );
}

export const TextInputWachtwoord = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const style = props.style;
  const returnKeyType = props.returnKeyType;
  const placeholder = props.placeholder;
  const input = props.input;
  const onChangeText = props.onChangeText;
  const blurOnSubmit = props.blurOnSubmit;
  const ref = props.ref;

  const requiredFunction = async () => {
    if (input === '') {
      setErrorMessage("Er gaat iets fout in wachtwoord");
      setError(true);
    } else {
      setError(false);
    }
  };

  return (<>

    <TextInput
      style={style}
      autoCapitalize='none'
      autoCorrect={false}
      returnKeyType={returnKeyType}
      placeholder={placeholder}
      value={input}
      secureTextEntry={true}
      onChangeText={onChangeText}
      onSubmitEditing={requiredFunction}
      blurOnSubmit={blurOnSubmit}
      ref={ref}
      autoComplete={ Platform.OS === 'web' ? 'none' : 'off' }
    />
    {error ? <P textAlign='center' color={'red'}>{errorMessage}</P> : null}
  </>
  );
}