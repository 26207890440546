// Alle imports
import { Dimensions } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import kleuren from "./kleuren";

import FncColors from '../styles/colors';
let Colors = [];

// Variabelllen die nodig zijn
const { width: winWidth, height: winHeight } = Dimensions.get("window");
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
EStyleSheet.build({
  $theme: "light",
  $rem: screenWidth / 380,
  $ram: screenHeight / 380,
});

export function mobieleStyling() {
  Colors = FncColors();

 return EStyleSheet.create({

  //Radio button
  RadioButtonView: {
    alignContent: 'center',
    paddingHorizontal: '20%',
  },
  AnswerBox: { 
        marginHorizontal: 10, 
        marginVertical: 10,  
  },
  swirlyContainers: {
    flex: 1,
    width: Dimensions.get('window').width,
  },
  // Userinput veld
  input: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    marginHorizontal: '20%',
    width: '70%',
    marginVertical: 20,
    borderColor: Colors[8],
    borderWidth: 1,
    borderRadius: 30,
  },
  input2: {
    paddingVertical: 5,
    paddingHorizontal: 14,
    marginHorizontal: '20%',
    marginVertical: 5,
    borderColor: Colors[8],
    borderWidth: 1,
    borderRadius: 30,
  },
  inputIos: {
    paddingVertical: 10,
    paddingHorizontal: 21,
    marginHorizontal: '20%',
    marginVertical: 5,
    borderColor: Colors[8],
    borderWidth: 1,
    borderRadius: 30,
  },
  // de X in de rechter bovenhoek.
  XInDeHoek: {
    position: 'absolute',
    alignSelf: 'flex-end',
    margin: '4%',
  },
  safeAreaView:{
    backgroundColor:"#ffffff",
    flex: 1
},
  // ThemeIcons
  iconDimensions: {
    width: 90,
    height: 88,
  },
  // Errorbox
  errorBoxAlign: {
    flexDirection: "row",
  },

  // Container met borderradius
  radiusView:{
    width: screenWidth * 0.8,
    height: screenHeight * 0.9,
  },
  // Topscreen Image
  topscreenImage: {
    marginTop: 5,
    marginBottom: 10,
    marginHorizontal: '12.5%',
    height: 200,
    width: 400,
  },
  // Oranje tekst
  oranje: {
    color: kleuren.oranje,
  },

  //Text en padding van de errorbow
  errorhandeling: {
    backgroundColor: kleuren.errorKleur,
    width: "100%",
    padding: 10,
    borderRadius: 20,
    textAlign: "center",
    color: "white",
    fontWeight: "600",
  },

  // Conditie kleur wanneer er geen gegevens bekend zijn
  warningColor: {
    color: kleuren.errorKleur,
    fontWeight: "bold",
    fontStyle: "italic",
    paddingLeft: 10,
  },

  // FlatList evenementedetailspage
  flatListInschrijvenTekst: {
    padding: 10,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    color: kleuren.oranje,
  },

  // Wanneer de waarde van een afstand false is
  flatListInschrijvenTekstFalse: {
    paddingVertical: 5,
    paddingHorizontal: 5,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    color: "grey",
  },
  // Wanneer er nog kan worden ingeschreven

  afstandKiezer: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: kleuren.wit,
    borderRadius: 20,
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      height: 1,
    },
    shadowOpacity: 0.65,
    shadowRadius: 3.85,
    elevation: 1,
  },
  // Wanneer de knop niet klikbaar meer moet zijn
  flatListFalseButton: {
    width: "100%",
    paddingVertical: 5,
  },
  // Padding per afstand
  flatListPadding: {
    alignItems: "center",
    width: "50%",
    paddingHorizontal: "10%",
    paddingVertical: 10,
  },
  // Warning tekst voor wanneer er al is ingeschreven
  allIngeschreven: {
    paddingHorizontal: 35,
    paddingBottom: 20,
    fontSize: 18,
    color: kleuren.errorKleur,
    fontWeight: "bold",
  },
  // Vaste variabelen om te herbruiken.
  // Ruimte tussen links en rechts
  ruimtelijkeOverzicht: {
    paddingHorizontal: 40,
  },
  // Flex container met kleur
  flex: {
    flex: 1,
    backgroundColor: kleuren.achtergrondKleur,
  },

  // Boven en onderafstand van elementen
  paddingVertical: {
    paddingVertical: 30,
  },

  // Tabnavigator icon grootte
  tabIcon: {
    fontSize: 35,
  },

  modalBackground: {
    flexGrow: 1,
    position: 'absolute',
    margin: 0,
  },
  modalPadding: {
    flex: 1,
    paddingRight: 20,
  },
  modalBorder: {
    flexGrow: 1,
    margin: 0,
  },
  // 3Dots modal
  // Modal positie
  modalView: {
    flexDirection: "column",
    backgroundColor: '#e1eef2',
    marginTop: 50,
    borderRadius: 20,
    padding: 30,
    paddingBottom: 0,
    shadowColor: "#000",
    shadowOffset: {
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  // Modal interface
  modalPosition: {
    flex: 0,
    alignSelf: "center",
    alignItems: "center",
    marginTop: 80,
    width: "90%",
    height: "40%",
  },
  resetModalPosition: {
    flex: 0,
    alignSelf: "center",
    alignItems: "center",
    marginTop: 35,
    marginLeft: '10%',
    width: "90%",
    height: "40%",
  },
  // Modal rij voor icon en tekst
  modalRow: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  // Icon styling
  modalIcon: {
    fontSize: 25,
    color: "grey",
    marginRight: 15,
    marginLeft: 10,
    marginTop: -25,
    textAlign: "left",
  },
  // Modal tekst
  modalTekst: {
    fontSize: 15,
    paddingBottom: 20,
    paddingLeft: 5,
    paddingRight: 5,
  },
  // Modal button
  modalButton: {
    backgroundColor: '#5a92f6',
    borderRadius: 30,//10,
    paddingHorizontal: 40,//25,
    paddingVertical: 15,//5,
    elevation: 10,
  },
  // Modal button klikbaaar
  modalButtonKlik: {
    marginTop: 10,
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 40,
    // borderColor: "black",
    // borderWidth: 1,
  },
  // Modal button tekst
  ModalButtonTekst: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  // Modal knop align
  modalButtoncenter: {
    alignItems: "center",
  },

  // FaciliteitenModal
  faciliteitenModal: {
    flex: 0.5,
    backgroundColor: "white",
    textAlign: "center",
    height: 200,
    width: "80%",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 20,
  },

  // FaciliteitenModalTekst
  faciliteitenModalTekst: {
    color: kleuren.donkerGrijs,
    paddingTop: 20,
    paddingBottom: 30,
    paddingHorizontal: 10,
    textAlign: "center",
    fontSize: 20,
  },

  // FaciliteitenModalHouder
  faciliteitenModalHouder: {
    width: "80%",
    height: 200,
    backgroundColor: "white",
    padding: 15,
    borderRadius: 20,
  },

  // faciliteitenModalhouder Speciaal voor de Tablet
  faciliteitenModalHouderTablet: {
    width: "100%",
    height: 200,
    backgroundColor: "white",
    padding: 15,
    borderRadius: 20,
  },

  exitIcon: {
    fontSize: 30,
    color: kleuren.oranje,
    textAlign: "right",
    marginTop: 70,
    paddingHorizontal: 35,
  },
  // Informatie titel
  infoTitel: {
    fontSize: 25,
    color: "grey",
    paddingHorizontal: 35,
    fontWeight: "800",
  },
  // Informatie subtitel
  infoSubTitel: {
    fontSize: 28,
    paddingHorizontal: 35,
    fontWeight: "bold",
  },
  // Paragraaf style
  infoParagraaf: {
    paddingTop: 15,
    fontSize: 20,
    paddingHorizontal: 35,
    color: "grey",
  },
  // Afbeelding container
  afbContainer: { justifyContent: "center", paddingHorizontal: 35 },
  // Het stylen van een afbeelding
  afbeeldingStyle: {
    marginTop: 30,
    alignSelf: "center",
    resizeMode: "stretch",
    height: 300,
    width: "100%",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  // Hoofdpagina evenementenoverzicht
  // Afbeelding
  achtergrond: {
    borderBottomLeftRadius: 40 / 2,
    borderBottomRightRadius: 40 / 2,
    overflow: "hidden",
    height: 400,
    width: "100%",
  },
  // Customized header om de navigatie in te regelen
  customHeader: {
    width: "100%",
    height: 30,
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 80,
  },

  // Beter klikbaar maken van de buttons linkerkant
  leftHeaderButton: {
    justifyContent: "center",
    padding: 9,
    height: 50,
    width: 50,
    alignSelf: "center",
    backgroundColor: "rgba(255,255,255, 0.2)",
    borderRadius: 50,
  },

  // Beter klikbaar maken van de buttons rechterkant
  rightHeaderButton: {
    justifyContent: "flex-start",
    padding: 9,
    alignSelf: "flex-start",
    backgroundColor: "rgba(255,255,255, 0.2)",
    borderRadius: 50,
  },

  // 3Dot icoon die bovenin de pagina te vinden is.
  navIcon: {
    color: kleuren.oranje,
    fontSize: 30,
    justifyContent: "center",
    alignSelf: "center",
  },

  // Titel van de schermpagina
  paginaTitel: {
    fontSize: 24,
    paddingTop: 15,
    paddingHorizontal: 35,
    paddingVertical: 5,
  },
  // Titel van inschrijfscherm
  inschrijfDetails: {
    fontSize: 20,
    paddingTop: 7,
    paddingHorizontal: 35,
    paddingVertical: 2,
  },
  // Titel van de content wat in de pagina zit
  titel: {
    fontSize: 25,
    color: "white",
    fontWeight: "bold",
    paddingHorizontal: 35,
    paddingVertical: 5,
  },
  // Subtitel die bovenaan de pagina staat
  subTitel: {
    paddingHorizontal: 35,
    paddingVertical: 5,
  },
  // Beschrijving van de pagina
  paragraaf: {
    fontSize: 16,
    paddingHorizontal: 35,
    paddingVertical: 5,
  },
  // Globale kleurinstelling voor de lettertype
  fontStyle: {
    color: kleuren.wit,
  },
  // Box voor de buttons datum en afstand
  box: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "space-around",
    justifyContent: "flex-start",
    backgroundColor: "white",
    borderRadius: 10,
    height: 60,
    top: -40,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginHorizontal: "10%",
    alignSelf: "center",
  },
  // Een box die bij evenementDetailspagina hoort
  boxje: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    alignItems: "baseline",
    backgroundColor: "white",
    borderRadius: 10,
    height: 60,
    top: -40,
    paddingVertical: 5,
    marginHorizontal: "10%",
  },

  // Sharebutton
  shareButton: {
    marginLeft: 35,
    paddingVertical: 10,
    justifyContent: "center",
    backgroundColor: kleuren.oranje,
    width: "60%",
    color: kleuren.wit,
    borderRadius: 20,
  },
  shareButtonTekst: {
    color: kleuren.wit,
    alignContent: "center",
  },

  // De row waar de touchableOpacity in zit
  boxItems: {
    flexDirection: "row",
    paddingLeft: 5,
    paddingRight: 5,
    color: "black",
    alignItems: "center",
    justifyContent: "center",
  },
  // Tekststyle voor de text in de touchableopacity
  boxtekststyle: {
    fontSize: 14,
    textAlign: "center",
    paddingVertical: 3,
  },
  // Icon element die achter de tekst zit verstopt
  boxArrow: {
    textAlign: "center",
    fontSize: 25,
    paddingRight: 2,
    paddingLeft: 0,
    marginTop: 3,
  },
  // Icon element die op het tekst zit
  boxIcon: {
    textAlign: "center",
    fontSize: 20,
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: 0,
  },
  // Icon element die op het tekst zit
  boxIconL: {
    textAlign: "center",
    fontSize: 20,
    paddingRight: 10,
    paddingLeft: 0,
    marginTop: 0,
  },
  // Scheiding streep tussen datum en afstand
  scheiding: {
    color: "#eaeaec",
    fontSize: 40,
    fontWeight: "100",
    textAlign: "center",
  },
  // Tekstelement om de aantal evenementen te weergeven
  h2: {
    paddingBottom: 20,
    paddingTop: 20,
    paddingHorizontal: 35,
    fontWeight: "bold",
    fontSize: 18,
  },
  h2Line: {
    marginTop: -15,
    marginLeft: 35,
    marginBottom: 10,
    height: 4,
    width: "85%",
    borderRadius: 20,
  },

  h1: {
    paddingBottom: 20,
    paddingTop: 20,
    paddingHorizontal: 35,
    fontWeight: "bold",
    fontSize: 25,
  },

  eventDetailParagraaf: {
    paddingHorizontal: 35,
    fontSize: 16,
    fontStyle: "italic",
    color: kleuren.tekstKleur,
  },

  h2Oranje: {
    color: kleuren.oranje,
    paddingBottom: 20,
    paddingTop: 20,
    paddingHorizontal: 40,
    fontWeight: "bold",
    fontSize: 18,
  },

  statusTekst: {
    fontSize: 20,
    fontWeight: "bold",
    color: kleuren.zwartGrijs,
  },
  // Afbeelding voor de registratie pagina met een kleinere hoogte vanwege de inputvelden.
  RegAchtergrond: {
    borderBottomLeftRadius: 40 / 2,
    borderBottomRightRadius: 40 / 2,
    justifyContent: "flex-start",
    overflow: "hidden",
    height: 300,
    width: "100%",
  },
  // Een aparte style voor de tekstweergave van de registratiepagina
  RegCustomHeader: {
    width: "100%",
    height: 60,
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingVertical: 20,
  },
  // Inputvelden voor registratie en wijzigingspagina van een gebruiker
  inputBox: {
    alignItems: "flex-start",
    backgroundColor: "white",
    borderRadius: 10,
    justifyContent: "flex-start",
    top: -40,
    marginHorizontal: 35,
    paddingHorizontal: 25,
    paddingBottom: 35,
  },
  // Tekstbox voor inschrijvingsdetails
  tekstBox: {
    alignItems: "flex-start",
    backgroundColor: "white",
    borderRadius: 10,
    justifyContent: "flex-start",
    top: -40,
    marginHorizontal: 35,
    paddingHorizontal: 50,
    paddingBottom: 35,
  },
  // Custom button style
  italyButton:{
    marginHorizontal: 35,
    padding: 15,
    textAlign: "center",
    backgroundColor: kleuren.oranje,
    borderRadius: 50,
  },
  acceptButton: {
    marginHorizontal: 35,
    padding: 15,
    textAlign: "center",
    backgroundColor: kleuren.oranje,
    borderRadius: 50,
  },
  // Button Titel
  buttonTitel: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
  },
  // Achtergond van een inputveld
  achtergrondInput: {
    paddingTop: 45,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderColor: kleuren.grijs,
  },

    // Achtergond van een inputveld
    achtergrondInputDisabled: {
      paddingTop: 45,
      paddingBottom: 5,
      alignItems: "center",
      flexDirection: "row",
    },
  // Tekst die bij de achtergrond moet komen te staan
  achtergrondTekst: {
    paddingTop: 45,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
  },
  // Inputveld van registratie en aanpaspagina
  inputStyle: {
    flex: 1,
    fontSize: 18,
    color: kleuren.oranje,
  },

  inputStyleDisabled: {
    flex: 1,
    fontSize: 18,
    color: 'grey',
  },

  dropdownStyle: {
    flex: 1,
    fontSize: 18,
    color: kleuren.oranje,
  },

  // Style van de tekst
  tekstStyle: {
    flex: 1,
    fontSize: 18,
    color: kleuren.oranje,
  },
  // icons die bij de inputvelden horen
  iconStyling: {
    fontSize: 25,
    paddingRight: 20,
    color: kleuren.grijs,
  },
  // Omhulsel van de inputveld
  borderColor: {
    color: kleuren.grijs,
  },
  // Styling wanneer erop een inputveld geklikt wordt
  iconActiefStyling: {
    fontSize: 25,
    paddingRight: 20,
    color: kleuren.oranje,
  },
  // Warning tekst wanneer er iets niet is ingevuld
  inputWarning: {
    color: kleuren.errorKleur,
    fontWeight: "bold",
    paddingTop: 10,
  },
  // Profielpagina styling
  proAchtergrond: {
    borderBottomLeftRadius: 40 / 2,
    borderBottomRightRadius: 40 / 2,
    overflow: "hidden",
    height: 350,
    width: "100%",
  },
  // Arrowknop
  headerButtons: {
    width: "100%",
    height: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 65,
    paddingHorizontal: 35,
  },

  headerButtonsForArrowandDots: {
    width: "100%",
    height: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 65,
    paddingHorizontal: 20,
  },
  // 3Dots knop
  headerButtonsRight: {
    height: 30,
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 65,
    padding: 9,
    height: 50,
    width: 50,
    alignSelf: "flex-end",
    backgroundColor: "rgba(255,255,255, 0.2)",
    borderRadius: 50,
  },
  // Een aparte style voor de tekstweergave
  ProCustomHeader: {
    width: "100%",
    height: 30,
    flexDirection: "row",
    paddingVertical: 5,
  },
  // Stylingselementen die kunnen worden overerft
  shadow: {
    shadowColor: "#000",
    shadowRadius: 0,
    shadowOpacity: 0.6,
    elevation: 20,
    shadowOffset: {
      width: 0,
      height: 4,
    },
  },
  // Shadow die om de flatlist heen zit
  shadowLight: {
    shadowColor: "#000",
    shadowRadius: 5,
    shadowOpacity: 0.7,
    elevation: 5,
    shadowOffset: {
      width: 0,
      height: 4,
    },
  },
  // Spacer die de afstand tussen elementen regelt
  spacer: {
    marginBottom: 40,
  },
  // EvenementDetailsBox

  // Evenement RegistratieError
  evenementRegistratieError: {
    paddingHorizontal: 35,
    paddingBottom: 20,
    fontSize: 18,
    color: kleuren.errorKleur,
    fontWeight: "bold",
  },

  iconbox: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 25,
    width: 70,
    height: 70,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 0.85,
    elevation: 1,
  },

  iconboxActive: {
    backgroundColor: kleuren.wit,
    borderRadius: 50,
    padding: 10,
    width: 70,
    height: 70,
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      height: 0,
    },

    shadowOpacity: 0.1,
    shadowRadius: 0.85,
    elevation: 6,
  },

  iconBoxIcons: {
    fontSize: 50,
    color: kleuren.oranje,
    textAlign: "center",
    alignContent: "center",
  },
  iconBoxIconsGrey: {
    fontSize: 50,
    color: kleuren.lichtGrijs,
    textAlign: "center",
    alignContent: "center",
  },

  // FaciliteitenIconhouder
  faciliteitenIconHouder: {
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "stretch",
  },

  //Deelbutton

  deelButton: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: kleuren.wit,
    paddingTop: 5,
    paddingTop: 15,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      height: 1,
    },
    shadowOpacity: 0.65,
    shadowRadius: 3.85,
    elevation: 1,
  },

  // Agendascherm
  miniBox: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    width: "45%",
    height: "80%",
    marginBottom: 30,
    justifyContent: "center",
    alignItems: "center",
  },

  tabletMiniBox: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 10,
    width: "30%",
    height: "80%",
    marginBottom: 50,
    marginLeft: 50,
    marginRight: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  // Icoontje in de box
  miniBoxIcon: {
    fontSize: 70,
    color: kleuren.oranje,
    textAlign: "center",
  },
  // Spacer om afstand te creeeren tussen elementen
  bottomSpacer: {
    paddingBottom: 30,
  },
  //Titel van de box
  boxTitel: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  // Subtekst van de boxen om data weer te geven
  boxParagraaf: {
    fontSize: 15,
    color: kleuren.donkerGrijs,
    textAlign: "center",
  },
  // Kleur van de button
  buttonColor: {
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  // Icoontje die in de button is verwerkt
  buttonIcon: {
    fontSize: 25,
    color: kleuren.oranje,
    padding: 10,
  },
  // Tekst die hoor bij de bovengenoemde button
  buttonTekst: {
    color: kleuren.oranje,
    fontSize: 20,
    fontWeight: "bold",
    padding: 5,
  },
  // Het annuleren van een handeling
  annuleerButton: {
    color: kleuren.oranje,
    fontSize: 20,
    fontWeight: "bold",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  // Afmetingen en plaatsing Vlag knoppen
  vlagButton: {
  padding: 10,
  width: 40,
  height: 40,
  position: 'absolute',
  marginHorizontal: 46,
  marginTop: 5
  },
  // Het centeren van items
  alignCenter: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  // Lijn die om de button heen moet vallen
  borderLine: {
    borderWidth: 2,
    borderRadius: 40,
    borderColor: kleuren.oranje,
    paddingHorizontal: 20,
  },
  // Betaal icoontje stylen
  idealImage: {
    width: 25,
    height: 25,
    paddingHorizontal: 15,
  },
  // Tekst kleuren en uitlijnen
  oranjeTekst: {
    fontSize: 16,
    color: kleuren.oranje,
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 30,
    textAlign: "center",
    fontWeight: "bold",
  },
  // AgendaPagina datum
  oranjeTekstDag: {
    fontSize: 16,
    color: kleuren.oranje,
    paddingTop: 20,
    fontWeight: "bold",
  },
  // AgendaPagina datum
  oranjeTekstMaand: {
    fontSize: 16,
    color: kleuren.oranje,
    paddingBottom: 20,
    fontWeight: "bold",
  },

  // Tekstveld
  tekst: {
    fontSize: 20,
    color: "black",
    textAlign: "center",
    padding: 10,
  },
  // Container voor de cards
  card: {
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 10,
    textAlign: "left",
    width: "80%",
    alignSelf: "center",
  },
  // Titel van de card
  cardTitel: {
    fontSize: 18,
    fontWeight: "700",
    color: "grey",
    paddingTop: 10,
  },
  spacingBox: {
    marginBottom: 10,
  },
  // Een gecustomized row
  row: {
    paddingVertical: 5,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingHorizontal: 15,
    alignItems: "center",
  },
  // Het uitlijnen van de row
  paddingRow: {
    paddingVertical: 5,
    paddingHorizontal: 5,
  },
  // Icon styling
  evenementDetailIcon: {
    fontSize: 30,
    justifyContent: "flex-end",
    alignItems: "flex-start",
    color: kleuren.oranje,
    paddingHorizontal: 5,
    paddingVertical: 20,
  },
  // Column maken 100%
  width100: {
    paddingLeft: 20,
    width: "100%",
  },
  // Column maken 80%
  width80: {
    paddingTop: 0,
    paddingLeft: 10,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "flex-start",
    width: "80%",
  },
  // Column maken 60%
  width60: {
    paddingTop: 10,
    width: "60%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  // Column maken 20%
  width20: {
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
  },
  // Foto holder
  cardImage: {
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden",
    height: 200,
  },

  tabletCardImage: {
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden",
    height: 350,
  },
  // Paragraaf tekst die in de card zit
  cardSubTitel: {
    color: kleuren.donkerGrijs,
    fontWeight: "500",
  },

  // Overzicht flatlist styling
  overzichtEvenementKaart: {
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 10,
    textAlign: "left",
    width: "97%",
    alignSelf: "center",
  },
  // Details overzicht
  detailsParagraaf: {
    color: kleuren.wit,
    paddingLeft: 35,
    fontWeight: "bold",
    fontSize: 15,
  },
  // Details overzicht maar dan zonder een linker padding
  detailsParagraafNoPad: {
    color: kleuren.wit,
    paddingLeft: 0,
    paddingTop: 3,
    fontWeight: "bold",
    fontSize: 18,
  },
  // Font Globaliseren
  font15: { fontSize: 13 },

  // Box naar links dwingen
  boxAlignL: {
    width: "50%",
    alignItems: "flex-start",
    paddingLeft: 40,
  },
  // Box naar rechts dwingen
  boxAlignR: {
    width: "50%",
    alignItems: "flex-end",
    paddingTop: 15,
    paddingRight: 40,
  },
  // Extra tekst styling
  TekstStyleExtras: {
    color: "grey",
    fontSize: 18,
    paddingTop: 10,
    paddingBottom: 5,
  },
  // Nog een ander column links
  columnLeft: {
    width: "50%",
    alignItems: "flex-start",
  },
  // Nog een ander column midden
  columnMiddle: {
    width: "30%",
    alignItems: "flex-end",
    alignContent: "center",
  },
  // Nog een ander column rechts
  columnRight: {
    width: "20%",
    alignItems: "flex-end",
    paddingTop: 15,
  },
  // Tekst die in de column moet komen
  textColumn: {
    color: "grey",
    fontSize: 18,
    paddingTop: 15,
    paddingBottom: 5,
  },
  // Prijs die in de column moet staan
  prijsColumn: {
    color: kleuren.prijsKleur,
    fontWeight: "bold",
    textAlign: "right",
    fontSize: 15,
    paddingTop: 18,
    paddingBottom: 5,
  },

  // Totaalprijs die wordt gebruikt in agendaScherm
  totaalPrijs: {
    color: kleuren.prijsKleur,
    fontWeight: "bold",
    fontSize: 20,
    paddingBottom: 10,
  },
  // Effect creeeren voor de totaalprijs
  totaalPrijsFX1: {
    color: kleuren.prijsKleurFX,
    fontWeight: "bold",
    fontSize: 20,
    paddingBottom: 10,
  },
  // Tekstbox per event
  perEventTekstBox: {
    alignItems: "flex-start",
    borderRadius: 100 / 5,
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    paddingLeft: 10,
    marginVertical: 10,
    backgroundColor: kleuren.wit,
    width: "100%",
  },
  // Styling van de categorie in persoonlijke event details
  categorieTitel: {
    fontSize: 20,
    fontWeight: "bold",
    color: kleuren.zwartGrijs,
  },
  paragraafEventDetails: {
    fontSize: 15,
    color: kleuren.zwartGrijs,
    paddingVertical: 5,
  },

  // Webview styling
  webview: {
    flex: 1,
    backgroundColor: "#f76e42",
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },

  // Terug knop van Webview
  websiteBack: {
    marginTop: "5%",
    backgroundColor: kleuren.oranje,
  },
});

}
