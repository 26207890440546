// Libraries 
import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import * as Font from 'expo-font';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking';
import { arrange_global_settings } from './src/functions/common';

const prefix = Linking.createURL('/');

import Loader from './src/components/loader';
import GlobalState from './src/context/globalState';
import ReadStorage from './src/components/readStorage';

//Functions

// Navigators
import HomeTabNavigator from './src/navigators/homeTabNavigator';

// Fonts
import fontfamilies from './src/options/fontFamilies';

// Variabels
global.url = window.location.href;
global.settings = specific_settings;
arrange_global_settings();
// AsyncStorage.clear();

//console.log("Test");

// Default function
export default function App() {
  const [fontsLoaded, setFontsLoaded] = useState( false );
  const fonts = fontfamilies();

  useEffect(() => {
    Font.loadAsync( fonts ).then(() => setFontsLoaded( true ));
  }, []);

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        Main: '/:module?',
        Code: '*?'
      },
    }
  }

//linking={linking}

  if ( !fontsLoaded ) {
    return <Loader/>;
  } else {  
    return (
      <GlobalState>
        <ReadStorage>
          <NavigationContainer linking={linking} >
            <HomeTabNavigator />
          </NavigationContainer>
        </ReadStorage>
      </GlobalState>
    );
  }
}

