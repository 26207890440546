// Libraries 
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

// Screens
import Main from '../screens/mainScreen';

// Variabele

// Default function


export default function HomeTabNavigator() {
    const Stack = createNativeStackNavigator();
    return (
        <Stack.Navigator> 
            <Stack.Screen name="Main" component= { Main } options={({ tabBarVisible: false, headerShown: false, animationEnabled: true, title: specific_settings.site_titel })} />
            <Stack.Screen name="Code" component= { Main } options={({ tabBarVisible: false, headerShown: false, animationEnabled: true, title: specific_settings.site_titel})} />
        </Stack.Navigator>
    )
};
